import React from 'react'
import { AvailabilityZone } from '../../Util'
import { Grid, Text } from '@chakra-ui/react'

export interface HoverInfo {
  availabilityZone: AvailabilityZone
  cloudProvder: string
  gridAverage: number
}

interface MapHoverProps {
  hoverInfo: HoverInfo | null
}

export const MapHover = (props: MapHoverProps) => {
  if (!props.hoverInfo) {
    return <></>
  }
  return props.hoverInfo
    ? <Grid templateColumns='auto auto auto auto' alignItems='center' gap={1} p={1}>
      <Text fontSize='md' fontWeight='bold' pr={1}>{props.hoverInfo.gridAverage}%</Text>
      <Text fontSize='md'>{props.hoverInfo.cloudProvder}</Text>
      <Text fontSize='md'>{props.hoverInfo.availabilityZone.name}</Text>
      <Text fontSize='md'>({props.hoverInfo.availabilityZone.azName})</Text>
    </Grid>
    : <></>
}
