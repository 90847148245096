import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalBody, Text, Stack, Button,
  useToast,
  Box,
  Flex,
  ModalOverlay
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { grey } from '../../Util'
import { Snackbar } from '../..//SharedComponents/Snackbar'
import { Tag } from 'react-feather'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { QueryInterval, updateSelectedTags } from '../../Api/Api'
import { useAuth0 } from '@auth0/auth0-react'

interface AddTagsModalProps {
  isOpen: boolean
  onClose: () => void
  connection?: MercuryConnection
}

export const AddTagsModal = (props: AddTagsModalProps) => {
  const [allTags, setAllTags] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [sendingRequest, setSendingRequest] = useState(false)
  const toast = useToast()
  const auth0 = useAuth0()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (props.connection) {
      setAllTags(props.connection.allTags)
      setSelectedTags(props.connection.selectedTags)
    }
  }, [props.connection])

  const addTags = useMutation(async () => {
    if (props.connection) {
      setSendingRequest(true)
      await updateSelectedTags(props.connection.id, selectedTags, auth0)
      setSendingRequest(false)
      toast({
        position: 'top-right',
        render: () => <Snackbar text="Tags will be updated within 24 hours" icon={<Tag />} />
      })
      queryClient.invalidateQueries({ queryKey: ['connections'] })
      queryClient.invalidateQueries({ queryKey: ['data', QueryInterval.RECENT] })
      queryClient.invalidateQueries({ queryKey: ['reingest'] })
      props.onClose()
    }
  })

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent className='connect-modal'>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={5} p={3}>
            <Stack gap={3}>
              <Text fontSize='xl'>
                <b>Add tags from {props.connection?.accountName} {props.connection?.cloudProvider}</b>
              </Text>
              <Stack>
                <Text fontSize='xs' color={grey(60)}>
                  Select tags to add to Mercury.
                </Text>
                <Box w='100%' h={60} border={`1px solid ${grey(60)}`} borderRadius={5}>
                  <Flex gap={2} p={2} flexWrap='wrap' rowGap={4} overflowY='auto'>
                    <Box
                      className={`tag ${selectedTags.length === allTags.length && 'tag-selected'}`}
                      onClick={() => {
                        if (selectedTags.length === allTags.length) {
                          setSelectedTags([])
                        } else {
                          setSelectedTags([...allTags])
                        }
                      }}>All Tags</Box>
                    {allTags.map(tagName => {
                      return <Box key={tagName}
                        className={`tag ${selectedTags.includes(tagName) && 'tag-selected'}`} onClick={() => {
                          if (selectedTags.includes(tagName)) {
                            setSelectedTags(selectedTags.filter(t => t !== tagName))
                          } else {
                            setSelectedTags([...selectedTags, tagName])
                          }
                        }}>{tagName}</Box>
                    })}
                  </Flex>
                </Box>
              </Stack>
            </Stack>
            <Button
              onClick={() => { addTags.mutate() }}
              color="#181818"
              isLoading={sendingRequest}
              _hover={{ background: grey(60) }}
              w={250}
            >
              Add
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
