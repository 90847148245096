import React from 'react'
import { Button, Grid, Stack, Text } from '@chakra-ui/react'
import { ReactMultiEmail } from 'react-multi-email'
import '../Onboarding.css'
import { grey } from '../../Util'

interface Step2Props {
  orgName: string
  teamMembers: string[]
  setTeamMembers: (teamMembers: string[]) => void
  advanceStep: () => void
}

export const Step2 = (props: Step2Props) => {
  return (
    <Stack gap={5} p={10}>
      <Text fontSize="sm" color={grey(60)}>Step 2 of 4</Text>
      <Stack gap={7} pb={10}>
        <Text fontSize='2xl'>Who else is on the {props.orgName} team?</Text>
        <Stack>
          <Text fontSize='xs' color={grey(60)}>
            Add teammates by email. Invites to join {props.orgName} will be sent as soon as the dashboard is live.
          </Text>
          <ReactMultiEmail
            className='step2Email'
            inputClassName='step2EmailInput'
            placeholder='name@organization.com, name@organization.com,...'
            emails={props.teamMembers}
            onChange={(emails: string[]) => {
              props.setTeamMembers(emails)
            }}
            autoFocus={true}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => { removeEmail(index) }}>
                    ×
                  </span>
                </div>
              )
            }}
          />
        </Stack>
      </Stack>
      <Grid templateColumns="16rem 10rem" gap={3}>
        <Button
          onClick={props.advanceStep}
          color="#181818"
          isDisabled={props.teamMembers.length === 0}
          _hover={{
            background: grey(60)
          }}
        >
          Next
        </Button>
        <Button onClick={props.advanceStep} variant="link" color={grey(60)}>
          <b>Skip this step</b>
        </Button>
      </Grid>
    </Stack>
  )
}
