import React, { useEffect, useState } from 'react'

import { MercuryMap } from './Map/MercuryMap'
import {
  getConnectionsForUser,
  getData,
  QueryInterval
} from '../Api/Api'
import { Header } from '../Header/Header'
import { Button, Divider, Grid, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { CarbonFreeScore } from './CarbonFreeScore/CarbonFreeScore'
import { StatusIndicator } from '../Header/StatusIndicator'
import { DashboardSelector } from './DashboardSelector/DashboardSelector'
import { Charts } from './Charts/Charts'
import { grey } from '../Util'
import { DashboardData } from './DashboardSelector/DataUtil'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { CloudProviderSelector, SelectedProviders } from '../Header/CloudProviderSelector'
import { IntervalSelector } from './IntervalSelector'

export const Dashboard = () => {
  const [interval, setInterval] = useState<QueryInterval>(QueryInterval.RECENT)
  const [connections, setConnections] = useState<MercuryConnection[]>([])
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    carbonFreeScore: 0,
    gridAverage: 0,
    totalUsage: 0,
    totalEmissions: 0,
    rawData: []
  })
  const [selectedZone, setSelectedZone] = useState<string>()
  const [showProviders, setShowProviders] = useState<SelectedProviders>({ AWS: true, Azure: false, GCP: false })

  const navigate = useNavigate()
  const auth0 = useAuth0()

  const {
    isLoading: connectionsLoading,
    data: connectionsData,
    error: connectionsError
  } = useQuery({
    queryKey: ['connections'],
    queryFn: async () => await getConnectionsForUser(auth0)
  })

  const {
    isLoading: queryLoading,
    data: queryData
  } = useQuery({
    queryKey: ['data', QueryInterval.RECENT],
    queryFn: async () => await getData(QueryInterval.RECENT, auth0),
    enabled: !connectionsLoading && connectionsData !== undefined
  })

  useEffect(() => {
    if (!connectionsLoading && connectionsData) {
      setConnections(connectionsData)
      if (connectionsData.length === 0) {
        navigate('/onboarding')
      }
    }
  }, [connectionsLoading, connectionsData])

  if (connectionsError) {
    throw new Error((connectionsError as Error).message)
  }

  return (
    <Grid templateRows='100px auto'>
      <Header>
        <Grid templateColumns='1fr auto auto auto' gap={5} alignItems='center' w='100%'>
          <Skeleton isLoaded={!connectionsLoading && !queryLoading} startColor={grey(12)}
            endColor={grey(24)} w='100%' h='100%'>
            {connections.length > 0 && queryData
              ? <StatusIndicator
                asOf={new Date(Math.max(
                  0, ...queryData.connectionData[connections[0].id].rawData.map(d => d.timestamp.getTime())
                ))} />
              : <></>
            }
          </Skeleton>
          <Grid templateColumns='1fr auto' justifySelf='center'>
            <CloudProviderSelector showProviders={showProviders} setShowProviders={setShowProviders} />
            <Divider orientation='vertical' opacity={0.12} />
          </Grid>
          <IntervalSelector setSelectedInterval={setInterval} selectedInterval={interval} />
          <Button bg={`linear-gradient(90deg, rgba(237, 210, 115, 0.87) -10.4%, ${grey(76)} 173%)`}
            color='#181818' as='a' href='https://mercurycomputing.io/get-our-data' fontSize={14}
            target='_blank' rel='noopener noreferrer' h={9}
            _hover={{
              bg: `linear-gradient(90deg, rgba(237, 210, 115, 1) -10.4%, ${grey(100)} 173%)`
            }}>
            GET DATA
          </Button>
        </Grid>
      </Header>
      <Grid maxH='calc(100vh - 100px)' templateRows='50% 50%' templateColumns='50% 50%'
        borderTop={`1px solid ${grey(12)}`} borderLeft={`1px solid ${grey(12)}`}>
        <Skeleton isLoaded={!connectionsLoading && !queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={connectionsLoading || queryLoading ? 6 : 0}>
          {connectionsData && queryData &&
            <DashboardSelector connections={connectionsData} queryData={queryData} setDashboardData={setDashboardData}
              showProviders={showProviders} selectedZone={selectedZone} setSelectedZone={setSelectedZone} />
          }
        </Skeleton>

        <Skeleton isLoaded={!connectionsLoading && !queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={connectionsLoading || queryLoading ? 6 : 0}>
          <CarbonFreeScore dashboardData={dashboardData} infoText={
            <Stack p={5} gap={3}>
              <Text>
                Your Carbon-free Score is the carbon-free percentage of the local grid,
                weighted by your hourly cloud usage in that region.
              </Text>
              <Text>
                The carbon-free percentage of the local grid is calculated using
                carbon-intensity data from the Energy Information Administration.
              </Text>
              <Text>
                Your cloud usage, measured in kilowatt-hours of electricity consumption,
                is based on the latest available data from your cloud service provider.
              </Text>
              <Text>
                Grid Average is the US-wide, average carbon-free percentage across all
                regions for a specific cloud service provider.
              </Text>
            </Stack>
          } />
        </Skeleton>

        <Skeleton isLoaded={!connectionsLoading && !queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={connectionsLoading || queryLoading ? 6 : 0}>
          <Charts data={dashboardData} />
        </Skeleton>

        <Skeleton isLoaded={!connectionsLoading && !queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={connectionsLoading || queryLoading ? 6 : 0}>
          {queryData &&
            <MercuryMap queryLoading={queryLoading} gridAverages={queryData.gridAverages}
              dashboardData={dashboardData} setSelectedZone={setSelectedZone} showProviders={showProviders} />
          }
        </Skeleton>
      </Grid>
    </Grid>
  )
}
