import { Flex, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React from 'react'
import { grey } from '../Util'

interface MercuryMenuProps {
  items: MercuryMenuItem[]
  menuButton?: React.ReactElement
  menuButtonSize?: 'xs' | 'sm' | 'md' | 'lg'
  menuIcon?: string
  extra?: React.ReactElement
  buttonLabel?: string
}

export interface MercuryMenuItem {
  icon: string
  text: string
  onClick?: () => void
  isDisabled?: boolean
}

export const MercuryMenu = (props: MercuryMenuProps) => {
  return (
    <Menu>
      {props.menuButton
        ? props.menuButton
        : <MenuButton as={IconButton} aria-label={props.buttonLabel || 'more'}
          icon={<Image src={props.menuIcon || '/icons/more.svg'} />} size={props.menuButtonSize || 'md'}
          bg={grey(5)} color={grey(87)} mr={0} ml='auto' _hover={{ bg: grey(23) }} _active={{ bg: grey(23) }}>
        </MenuButton>}
      <MenuList bg='#212121' border={`1px solid ${grey(24)}`} p={0}>
        {props.extra || <></>}
        {props.items.map(item => (
          <MenuItem bg={grey(12)} _hover={{ bg: grey(24) }} p={2.5} isDisabled={item.isDisabled}
            onClick={item.onClick ? item.onClick : () => { }} key={item.text}>
            <Flex alignItems='start' gap={2}>
              <Image src={item.icon} h={5} />
              <Text fontSize='sm'>{item.text}</Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
