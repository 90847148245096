import { Button, Flex, Grid, Text } from '@chakra-ui/react'
import React from 'react'
import { grey } from '../Util'
import { QueryInterval } from '../Api/Api'

interface IntervalSelectorProps {
  selectedInterval: QueryInterval
  setSelectedInterval: (interval: QueryInterval) => void
}

export const IntervalSelector = (props: IntervalSelectorProps) => {
  return (
    <Grid w={220} templateColumns='repeat(3, 1fr)' gap={3} color={grey(87)}>
      <Button bg={props.selectedInterval === QueryInterval.RECENT ? grey(24) : grey(0)}
        _hover={{ bg: grey(24) }} w={75} p={0}
        onClick={() => { props.setSelectedInterval(QueryInterval.RECENT) }}>
        <Flex gap={1} alignItems='inherit'>
          <Text fontWeight='bold'>24</Text><Text fontSize='xs'>hours</Text>
        </Flex>
      </Button>
      <Button bg={props.selectedInterval === QueryInterval.THIRTYDAYS ? grey(24) : grey(0)}
        _hover={{ bg: grey(24) }} w={70} p={0} isDisabled
        onClick={() => { props.setSelectedInterval(QueryInterval.THIRTYDAYS) }}>
        <Flex gap={1} alignItems='inherit'>
          <Text fontWeight='bold'>30</Text><Text fontSize='xs'>days</Text>
        </Flex>
      </Button>
      <Button bg={props.selectedInterval === QueryInterval.YEARTODATE ? grey(24) : grey(0)}
        _hover={{ bg: grey(24) }} w={54} p={0} isDisabled
        onClick={() => { props.setSelectedInterval(QueryInterval.YEARTODATE) }}>
        <Flex gap={1} alignItems='inherit'>
          <Text fontWeight='bold'>YTD</Text>
        </Flex>
      </Button>
    </Grid>
  )
}
