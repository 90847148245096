import { MercuryConnection, MercuryDataPoint } from '@headway-cooperative/project-mercury-utils'
import { TagSelection } from './DashboardOptionSelector'
import { DashboardDataResponse } from '../../Api/Api'
import { SelectedProviders } from '../../Header/CloudProviderSelector'
import { Grid, IconButton, Image, Text } from '@chakra-ui/react'
import { EMPTY_TAG } from '../../Util'
import { DashboardSelectorGroup } from './DashboardGroupSelector'
import { FilterAs } from './DashboardSelectorSort'

export const getTagsGroup = (
  connections: MercuryConnection[],
  setConnectionForTagsModal: (connection: MercuryConnection) => void,
  onAddTagsOpen: () => void,
  disableConnections?: boolean
): DashboardSelectorGroup => ({
  name: 'Tags',
  element: (
    <Grid w='100%' gridTemplateColumns='2rem 1fr auto'>
      <Image src='/icons/tag.svg' />
      <Text fontWeight='bold' fontSize='sm'>Tags</Text>
      <IconButton size='xs' variant='link' icon={<Image src='/icons/pencil.svg' />}
        isDisabled={connections.filter(c => c.allTags?.length > 0).length === 0} // some connection has tags
        aria-label='edit-tags' onClick={() => {
          // use the first connection that has tags
          setConnectionForTagsModal(connections.filter(c => c.allTags.length > 0)[0])
          onAddTagsOpen()
        }} />
    </Grid>
  ),
  disabled: disableConnections
})

export const getTagOptions = (
  connections: MercuryConnection[],
  queryData: DashboardDataResponse,
  showProviders: SelectedProviders
) => {
  const newTags: Record<string, TagSelection> = {}
  let totalUsage = 0
  connections.forEach(c => {
    if (showProviders[c.cloudProvider]) {
      if (queryData.connectionData[c.id]) {
        queryData.connectionData[c.id].rawData.forEach(d => {
          if (!d.tags) {
            return
          }
          Object.keys(d.tags).forEach(tagName => {
            const tagValue = d.tags[tagName] || EMPTY_TAG
            const key = `${tagName}:${tagValue}`
            const ts = newTags[key]
            if (ts === undefined) {
              newTags[key] = {
                key,
                name: tagName,
                tagValue,
                usage: 0,
                emissions: 0,
                cost: 0,
                cfUsage: 0
              }
            }
            totalUsage += d.kWh
            newTags[key].usage += d.kWh
            newTags[key].emissions += (d.kWh * d.carbonIntensity) / 1000
            newTags[key].cost += d.cost
            newTags[key].cfUsage += (d.kWh * (d.carbonFreePercentage / 100))
          })
        })
      }
    }
  })
  return Object.values(newTags).filter(t => t.usage / totalUsage > 0.01)
}

export const getTagDataFilter = (
  filterAs: FilterAs,
  selectedTags: TagSelection[],
  selectedGroup?: DashboardSelectorGroup
) => {
  return (d: MercuryDataPoint) => {
    if (selectedGroup?.name !== 'Tags') {
      return true
    }
    if (selectedTags.length === 0) {
      return false
    }
    const matches = (ts: TagSelection) => {
      const tagValue = ts.tagValue === EMPTY_TAG ? null : ts.tagValue
      return d.tags[ts.name] === tagValue
    }
    if (filterAs === 'union') {
      return selectedTags.filter(matches).length > 0
    } else {
      return selectedTags.filter(matches).length === selectedTags.length
    }
  }
}
