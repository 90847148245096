import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType } from 'react'
import { LoadingSpinner } from '../SharedComponents/LoadingSpinner'

export const AuthenticationGuard = ({ component }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <LoadingSpinner />
    )
  })

  return <Component />
}
