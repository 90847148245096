import { Grid, Text } from '@chakra-ui/react'
import { DashboardSelectorOption } from './DashboardOptionSelector'
import { UsageFacet, getFacetValue } from '../../SharedComponents/UsageFacetSwitch'
import { grey, valueToColor } from '../../Util'
import { Tooltip } from 'react-tooltip'

interface DashboardSelectorOptionViewProps {
  option: DashboardSelectorOption
  onSelect: (option: DashboardSelectorOption) => void
  selected: boolean
  text: string
  usageFacet: UsageFacet
}

export const DashboardSelectorOptionView = (props: DashboardSelectorOptionViewProps) => {
  return (
    <Grid key={props.option.key} templateColumns='1fr auto' justifyContent='stretch'
      onClick={() => { props.onSelect(props.option) }} className={props.selected
        ? 'dashboard-selector-option dashboard-selector-selected'
        : 'dashboard-selector-option'}>
      <Text fontWeight='bold' fontSize='sm' overflow='hidden' whiteSpace='nowrap'>{props.text}</Text>
      {props.usageFacet === 'carbonfree'
        ? <svg height={15} width={15} data-tooltip-id='dashboard-option-selector-toolip'
          data-tooltip-content={getFacetValue(props.usageFacet, props.option)}>
          <circle cx="50%" cy="50%" r="50%" style={{
            fill: valueToColor((props.option.cfUsage / props.option.usage) * 100, 100),
            cursor: 'pointer'
          }} />
        </svg>
        : <Text fontWeight='bold' fontSize='sm'>{Math.round(props.option[props.usageFacet])}</Text>
      }
      <Tooltip border={`1px solid ${grey(24)}`} id='dashboard-option-selector-toolip' />
    </Grid>
  )
}
