import { Button, ButtonGroup, Grid, Text } from '@chakra-ui/react'
import { grey } from '../../Util'

export type SortBy = 'value' | 'name'
export type FilterAs = 'intersection' | 'union'

interface DashboardSelectorSortProps {
  sortBy: SortBy
  setSortBy: (sortBy: SortBy) => void
  filterAs: FilterAs
  setFilterAs: (filterAs: FilterAs) => void
}

export const DashboardSelectorSort = (props: DashboardSelectorSortProps) => {
  return (
    <Grid templateColumns='auto auto' gap={2}>
      <ButtonGroup isAttached size='sm' w={75}>
        <Button color={props.sortBy === 'value' ? grey(87) : grey(60)}
          bg={props.sortBy === 'value' ? grey(24) : grey(0)}
          borderColor={props.sortBy === 'value' ? grey(0) : grey(24)}
          onClick={() => { props.setSortBy('value') }} variant='outline' _hover={{ bg: grey(24) }}>
          <Text fontSize='sm'>123</Text>
        </Button>
        <Button color={props.sortBy === 'name' ? grey(87) : grey(60)}
          bg={props.sortBy === 'name' ? grey(24) : grey(0)}
          borderColor={props.sortBy === 'name' ? grey(0) : grey(24)}
          onClick={() => { props.setSortBy('name') }} variant='outline' _hover={{ bg: grey(24) }}>
          <Text fontSize='sm'>abc</Text>
        </Button>
      </ButtonGroup>
      <ButtonGroup isAttached size='sm' w={80}>
        <Button color={props.filterAs === 'intersection' ? grey(87) : grey(60)}
          bg={props.filterAs === 'intersection' ? grey(24) : grey(0)}
          borderColor={props.filterAs === 'intersection' ? grey(0) : grey(24)}
          onClick={() => { props.setFilterAs('intersection') }} variant='outline' _hover={{ bg: grey(24) }}>
          <Text fontSize='sm'>AND</Text>
        </Button>
        <Button color={props.filterAs === 'union' ? grey(87) : grey(60)}
          bg={props.filterAs === 'union' ? grey(24) : grey(0)}
          borderColor={props.filterAs === 'union' ? grey(0) : grey(24)}
          onClick={() => { props.setFilterAs('union') }} variant='outline' _hover={{ bg: grey(24) }}>
          <Text fontSize='sm'>OR</Text>
        </Button>
      </ButtonGroup>
    </Grid>
  )
}
