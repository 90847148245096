import { MercuryDataPoint } from '@headway-cooperative/project-mercury-utils'
import { ConnectionData } from '../../Api/Api'

export interface DashboardData {
  carbonFreeScore: number
  gridAverage: number
  totalUsage: number
  totalEmissions: number
  rawData: MercuryDataPoint[]
}

export const aggregateDashboardData = (
  connectionData: ConnectionData[],
  gridAverages: Record<string, number>,
  cloudProvider?: string,
  availabilityZone?: string,
  orgId?: string,
  tagFilter?: (d: MercuryDataPoint) => boolean
): DashboardData => {
  let filtered: ConnectionData[] = [...connectionData]
  if (cloudProvider) {
    filtered = filtered.filter(d => d.connection.cloudProvider === cloudProvider)
  }
  if (orgId) {
    filtered = filtered.filter(d => d.connection.mercuryOrganization.id === orgId)
  }
  const result = {
    carbonFreeScore: 0,
    gridAverage: 0,
    totalEmissions: 0,
    totalUsage: 0,
    rawData: []
  } as DashboardData

  filtered.forEach(cd => {
    cd.rawData.forEach(d => {
      if (availabilityZone && d.availabilityZone !== availabilityZone) {
        return
      }
      if (tagFilter && !tagFilter(d)) {
        return
      }
      result.totalUsage += d.kWh
      result.totalEmissions += (d.kWh * d.carbonIntensity)
      result.rawData.push(d)
    })
  })

  result.gridAverage = Object.values(gridAverages).reduce((a, b) => a + b, 0) / Object.keys(gridAverages).length

  result.carbonFreeScore = result.rawData.reduce((sum, d) => (
    sum + ((d.kWh / (result.totalUsage || 1)) * d.carbonFreePercentage)
  ), 0)

  return result
}
