import React, { MouseEvent } from 'react'
import { Marker } from 'react-map-gl'
import { AvailabilityZone, grey, valueToColor } from '../../Util'
import { HoverInfo } from './MapHover'

export interface ZoneMarkerProps {
  availabilityZone: AvailabilityZone
  cloudProvider: string
  onClick: () => void
  gridAverage: number
  setHoverInfo: (hoverInfo: HoverInfo) => void
  selected: boolean
  visible: boolean
}

export const ZoneMarker = (props: ZoneMarkerProps) => {
  const onHover = (event: MouseEvent<HTMLDivElement>) => {
    props.setHoverInfo({
      availabilityZone: props.availabilityZone,
      cloudProvder: props.cloudProvider,
      gridAverage: Math.round(props.gridAverage)
    } as HoverInfo)
  }

  return (
    <Marker
      longitude={props.availabilityZone.lng}
      latitude={props.availabilityZone.lat}
      onClick={props.onClick}
    >
      <div
        onMouseEnter={onHover}
        style={{ cursor: 'default' }}
        className="map-marker"
      >
        <MapCircle
          fill={props.visible ? valueToColor(props.gridAverage, 100) : grey(38)}
          selected={props.selected}
        />
      </div>
    </Marker>
  )
}

interface MapCircleProps {
  fill: string
  selected: boolean
}
export const MapCircle = (props: MapCircleProps) => {
  const markerStyle = {
    fill: props.fill,
    cursor: 'pointer'
  }

  const selectedStyle = {
    fill: 'rgb(100, 100, 100)',
    stroke: 'rgb(100, 100, 100)'
  }

  return (
    <svg height={props.selected ? 45 : 30} width={props.selected ? 45 : 30}>
      {props.selected && <circle cx="50%" cy="50%" r='35%' style={selectedStyle} />}
      <circle cx="50%" cy="50%" r="20%" style={markerStyle} />
    </svg>
  )
}
