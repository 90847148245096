import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { DashboardSelectorOption } from './DashboardOptionSelector'
import { DashboardDataResponse } from '../../Api/Api'
import { SelectedProviders } from '../../Header/CloudProviderSelector'
import { Flex, Image, Text } from '@chakra-ui/react'
import { DashboardSelectorGroup } from './DashboardGroupSelector'

export const getMyCloudsGroup = (disableConnections?: boolean): DashboardSelectorGroup => ({
  name: 'My Clouds',
  element: (
    <Flex gap={2.5}>
      <Image src='/icons/connections.svg' />
      <Text fontWeight='bold' fontSize='sm'>My Clouds</Text>
    </Flex>
  ),
  disabled: disableConnections
})

export const getCloudOptions = (
  connections: MercuryConnection[],
  queryData: DashboardDataResponse,
  showProviders: SelectedProviders
) => {
  const options: Record<string, DashboardSelectorOption> = {}
  connections.forEach(c => {
    if (showProviders && !showProviders[c.cloudProvider]) {
      return
    }
    const name = `${c.mercuryOrganization.name} ${c.cloudProvider}`
    if (!options[name]) {
      options[name] = {
        key: name,
        name,
        cloudProvider: c.cloudProvider,
        orgId: c.mercuryOrganization.id,
        usage: 0,
        emissions: 0,
        cost: 0,
        cfUsage: 0
      }
    }
    if (queryData.connectionData[c.id]) {
      queryData.connectionData[c.id].rawData.forEach(d => {
        options[name].usage += d.kWh
        options[name].emissions += (d.kWh * d.carbonIntensity) / 1000
        options[name].cfUsage += (d.kWh * (d.carbonFreePercentage / 100))
        options[name].cost += d.cost
      })
    }
  })
  return Object.values(options)
}
