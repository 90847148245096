import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay
} from '@chakra-ui/react'
import React from 'react'

interface ErrorPopupProps {
  message: string | React.ReactElement
}

export const ErrorPopup = (props: ErrorPopupProps) => {
  return (
    <AlertDialog
      isOpen={true}
      /* @ts-expect-error not sure how to handle this, but the ts error is wrong */
      leastDestructiveRef={null}
      onClose={() => {
      }}
      style={{ color: 'black' }}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold' color='hw.700'>
            Error
          </AlertDialogHeader>
          <AlertDialogBody color='hw.700'>
            {props.message}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
