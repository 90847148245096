import { Box, Flex, Grid, Text } from '@chakra-ui/react'

export const MapLegend = () => {
  return (
    <Grid h={14} w={60} p={1} m='0 0 1rem 2rem' gap={0.2} className="map-legend">
      <Text pl={1} fontSize="xs" alignSelf='end'>REGION AVERAGE</Text>
      <Flex>
        <Box m={1} w="75%" className="map-legend-gradient" borderRadius={10} />
        <Text fontSize='xs'>100%</Text>
      </Flex>
    </Grid>
  )
}
