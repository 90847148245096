import { Box, Flex, Grid } from '@chakra-ui/react'
import { grey } from '../../Util'
import { DashboardSelectorOption } from './DashboardOptionSelector'

export interface DashboardSelectorGroup {
  name: string
  element: React.ReactElement
  disabled?: boolean
}

interface DashboardGroupSelectorProps {
  groups: DashboardSelectorGroup[]
  selectedGroup?: DashboardSelectorGroup
  setSelectedGroup: (group: DashboardSelectorGroup) => void
  setSelectedOption: (option: DashboardSelectorOption) => void
}

export const DashboardGroupSelector = (props: DashboardGroupSelectorProps) => {
  return (
    <Grid borderRight={`1px solid ${grey(12)}`} p={4} overflowY='auto' overflowX='hidden' gap={2}
      gridAutoRows='min-content'>
      {props.groups.map(g => (
        <Box key={g.name} onClick={() => {
          if (!g.disabled) {
            props.setSelectedGroup(g)
          }
        }}
          className={props.selectedGroup?.name === g.name ? 'dashboard-selector-selected' : ''}>
          <Flex className={`dashboard-selector-option ${g.disabled ? 'dashboard-selector-option-disabled' : ''}`}>
            {g.element}
          </Flex>
        </Box>
      )
      )}
    </Grid>
  )
}
