import React from 'react'
import { Button, Grid, Stack, Text } from '@chakra-ui/react'
import { Check, GitHub } from 'react-feather'
import { getCreateStackURL, grey } from '../../Util'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'

interface Step4Props {
  connection: MercuryConnection | null
  openSendLinkModal: () => void
  hideStep?: boolean
}

export const Step4 = (props: Step4Props) => {
  const templateLink = (
    <Grid templateColumns='auto 1fr' gap={2} alignItems='center'>
      <Text fontSize='xs' color={grey(60)} pt={3}>
        <b><a href='https://github.com/Headway-Cooperative/project-mercury-public/blob/main/aws/mercury-template.yaml'>
          see our template
        </a></b>
      </Text>
      <GitHub color={grey(60)} />
    </Grid>
  )
  return (
    <Stack gap={5} p={10}>
      {!props.hideStep && <Text fontSize="sm" color={grey(60)}>Step 4 of 4</Text>}
      <Stack gap={7} pb={10}>
        <Text fontSize='2xl'>
          Connect {props.connection?.mercuryOrganization.name} {props.connection?.cloudProvider} to Mercury
        </Text>
        <Stack>
          <Text fontSize='xs' color={grey(60)} pb={3}>
            Before you hit connect, please verify the following:
          </Text>
          <Grid templateColumns='40px auto' alignItems='center'>
            <Check color={grey(60)} />
            <Text fontSize='xs' color={grey(60)}>
              You are signed in to the AWS console
            </Text>
          </Grid>
          <Grid templateColumns='40px auto' alignItems='center'>
            <Check color={grey(60)} />
            <Text fontSize='xs' color={grey(60)}>
              Your region is set to AWS us-east-1
            </Text>
          </Grid>
          <Grid templateColumns='40px auto' alignItems='center'>
            <Check color={grey(60)} />
            <Text fontSize='xs' color={grey(60)}>
              You have AWS billing (read only), S3, and Cloud Formation permissions
            </Text>
          </Grid>
          <Grid templateColumns='auto 1fr' gap={1}>
            <Text fontSize='xs' color={grey(60)} pt={3}>
              Curious how it works? <b><a href='/faq'>Read the FAQ</a></b> or
            </Text>
            {templateLink}
          </Grid>
        </Stack>
      </Stack>
      <Grid templateColumns="17rem 8.1rem auto" gap={3}>
        <Button
          as='a'
          href={getCreateStackURL(props.connection)}
          target='_blank'
          rel='noopener noreferrer'
          color="#181818"
          _hover={{
            background: grey(60)
          }}
          mr={2}
        >
          Connect
        </Button>
        <Text fontSize='xs' color={grey(60)} pt={3}>
          Not the right person?
        </Text>
        <Button
          justifySelf='start'
          variant="link"
          fontSize='xs'
          color={grey(60)}
          onClick={props.openSendLinkModal}
        >
          Ask a teammate for help
        </Button>
      </Grid>
    </Stack>

  )
}
