import React from 'react'
import { grey, valueToColor } from '../../Util'
import { Bar, BarChart, CartesianGrid, Cell, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CustomTooltip } from './CustomTooltip'
import { Text } from '@chakra-ui/react'
import { MercuryDataPoint } from '@headway-cooperative/project-mercury-utils'

interface UsageEmissionsChartProps {
  data: MercuryDataPoint[]
  usageEmissions: string
}

export const UsageEmissionsChart = (props: UsageEmissionsChartProps) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart data={props.data} syncId='dashboardCharts'>
        <CartesianGrid vertical={false} stroke={grey(12)} />
        <XAxis dataKey='timestamp' tick={false} />
        <YAxis orientation='left' style={{ fontSize: 12 }} axisLine={false} tickLine={false}>
          <Label
            value={props.usageEmissions === 'usage' ? 'USAGE kWh' : 'EMISSIONS kgCO₂e'}
            position={{ x: 25, y: 20 }}
            angle={-90}
            style={{ fontSize: 12 }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip labelFormatter={(label, payload) => {
          return <Text fontSize='xs' fontWeight='bold'>{Math.round(payload[0].value!)} kWh</Text>
        }} />} />
        <Bar dataKey='kWh'>
          {props.data.map((entry, index) => {
            return <Cell fill={valueToColor(entry.carbonFreePercentage, 100)} key={`cell-${index}`} />
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
