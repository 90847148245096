import { Button, Grid, Spinner } from '@chakra-ui/react'
import { DashboardSelectorGroup } from './DashboardGroupSelector'
import { HasUsageFacets, UsageFacet } from '../../SharedComponents/UsageFacetSwitch'
import { DashboardSelectorOptionView } from './DashboardSelectorOption'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getReingestJobs } from '../../Api/Api'
import { useQuery } from '@tanstack/react-query'
import { Plus } from 'react-feather'
import { grey } from '../../Util'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'

export interface DashboardSelectorOption extends HasUsageFacets {
  name: string
  orgId?: string
  availabilityZone?: string
  cloudProvider?: string
  usage: number
  emissions: number
  cost: number
  cfUsage: number
}

export interface TagSelection extends DashboardSelectorOption {
  tagValue: string
}

interface DashboardOptionSelectorProps {
  connections: MercuryConnection[]
  group?: DashboardSelectorGroup
  options: DashboardSelectorOption[]
  selectedOption?: DashboardSelectorOption
  setSelectedOption: (option: DashboardSelectorOption) => void
  selectedTags: TagSelection[]
  setSelectedTags: (selectedTags: TagSelection[]) => void
  onAddTagsOpen: () => void
  setConnectionForTagsModal: (connection: MercuryConnection) => void
  usageFacet: UsageFacet
}

export const DashboardOptionSelector = (props: DashboardOptionSelectorProps) => {
  const [isLoadingTags, setIsLoadingTags] = useState<boolean>(true)
  const auth0 = useAuth0()
  const {
    isLoading: reingestLoading,
    data: reingestData
  } = useQuery({
    queryKey: ['reingest'],
    queryFn: async () => await getReingestJobs(auth0)
  })

  useEffect(() => {
    if (!reingestLoading && reingestData?.connectionIds) {
      setIsLoadingTags(reingestData.connectionIds.length > 0)
    }
  }, [reingestData, reingestLoading])

  const onSelect = (o: DashboardSelectorOption) => {
    if (props.group?.name === 'Tags') {
      if (isSelected(o)) {
        props.setSelectedTags(props.selectedTags.filter(t => t.key !== o.key))
      } else {
        props.setSelectedTags([o as TagSelection, ...props.selectedTags])
      }
    } else {
      props.setSelectedOption(o)
    }
  }

  const isSelected = (option: DashboardSelectorOption) => {
    if (props.group?.name === 'Tags') {
      return props.selectedTags.map(t => t.key).includes(option.key)
    } else {
      return props.selectedOption?.key === option.key
    }
  }

  const getText = (option: DashboardSelectorOption) => {
    if (props.group?.name === 'Tags') {
      const tag = option as TagSelection
      return `${tag.name}: ${tag.tagValue}`
    } else {
      return option.name
    }
  }

  return (
    <Grid mt={4} overflowY='auto' overflowX='hidden' gap={2} gridAutoRows='min-content'>
      {!props.group || props.group.name !== 'Tags' || props.options.length > 0
        ? props.options.map(o => <DashboardSelectorOptionView key={o.key} onSelect={onSelect}
          selected={isSelected(o)} option={o} usageFacet={props.usageFacet} text={getText(o)} />)
        : <Button m='0 auto' bg={grey(0)} _hover={{ bg: grey(24) }}
          leftIcon={isLoadingTags ? <Spinner /> : <Plus />}
          isDisabled={isLoadingTags || props.connections.filter(c => c.allTags.length > 0).length === 0}
          onClick={() => {
            props.setConnectionForTagsModal(props.connections.filter(c => c.allTags.length > 0)[0])
            props.onAddTagsOpen()
          }}>
          Add Tags
        </Button>
      }
    </Grid>
  )
}
