import React from 'react'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'

export interface GaugeProps {
  value: number
  maxValue: number
  fill: string
  units: string
  children: React.ReactElement
  labelExtra?: string
}

export const Gauge = (props: GaugeProps) => {
  const data = [
    { value: Math.min(props.value, props.maxValue) },
    { value: props.maxValue - Math.min(props.value, props.maxValue) }
  ]

  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Pie
            startAngle={450}
            endAngle={90}
            innerRadius="70%"
            dataKey="value"
            data={data}
            labelLine={false}
            blendStroke
            isAnimationActive={false}
          >
            <Cell fill={props.fill} />
            <Cell fill="#333" />
            <Label content={props.children} />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}
