import React, { useEffect, useState } from 'react'
import './Charts.css'
import { Grid } from '@chakra-ui/react'
import { UsageEmissionsChart } from './UsageEmissionsChart'
import { CarbonFreeChart } from './CarbonFreeChart'
import { DashboardData } from '../DashboardSelector/DataUtil'
import { MercuryDataPoint, groupBy } from '@headway-cooperative/project-mercury-utils'
import { groupData } from '../../Util'

interface ChartsProps {
  data: DashboardData
}

export const Charts = (props: ChartsProps) => {
  const [chartData, setChartData] = useState<MercuryDataPoint[]>([])
  useEffect(() => {
    const groupedData = groupData(props.data.rawData, ['timestamp', 'availabilityZone'])
    const groupedByZone = groupBy(groupedData, ['availabilityZone'])
    if (Object.keys(groupedByZone).length > 1) {
      /*
        This calculation is a bit complex.
        - First aggregate the data by zone and timestamp
        - Then get the average usage for each zone accross all timestamps
        - The average usage is used a weighting for the average zone CF% at each timestamp
        - CF%@Time = SUM(ZoneCF%@Time * UsageWeight)
        - where UsageWeight = ZoneAvgUsage / SUM(ZoneAvgUsages)
      */
      const weightsByZone: Record<string, number> = {}
      Object.values(groupedByZone).forEach(values => {
        const zoneTotal = values.reduce((sum: number, v: MercuryDataPoint) => sum + v.kWh, 0)
        weightsByZone[values[0].availabilityZone] = zoneTotal / values.length
      })
      const zoneAverageSum = Object.values(weightsByZone).reduce((sum, a) => sum + a, 0)
      Object.keys(weightsByZone).forEach(z => { weightsByZone[z] = weightsByZone[z] / (zoneAverageSum || 1) })

      const newChartData = groupData(groupedData, ['timestamp'], (d) => weightsByZone[d.availabilityZone])
        .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
      setChartData(newChartData)
    } else {
      const newChartData = groupData(groupedData, ['timestamp'])
        .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
      setChartData(newChartData)
    }
  }, [props.data])
  return (
    <Grid className='charts'>
      <UsageEmissionsChart data={chartData} usageEmissions={'usage'} />
      <CarbonFreeChart data={chartData} />
    </Grid>
  )
}
