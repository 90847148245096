import React from 'react'
import { Button, Grid, Input, Stack, Text } from '@chakra-ui/react'
import { grey } from '../../Util'

interface Step1Props {
  setOrgName: (orgName: string) => void
  advanceStep: () => void
}

export const Step1 = (props: Step1Props) => {
  return (
    <Stack gap={5} p={10}>
      <Text fontSize="sm" color={grey(60)}>Step 1 of 4</Text>
      <Stack gap={7} pb={10}>
        <Text fontSize='2xl'>What's the name of your company or team?</Text>
        <Stack>
          <Text fontSize='sm' color={grey(60)}>
            This will be the name of your Mercury dashboard—choose something that your team will recognize.
          </Text>
          <Input onChange={(e) => { props.setOrgName(e.target.value) }} placeholder='Company or team name' w={400} />
        </Stack>
      </Stack>
      <Grid templateColumns="16rem 10rem" gap={3}>
        <Button
          onClick={props.advanceStep}
          color="#181818"
          _hover={{
            background: grey(60)
          }}
        >
          Next
        </Button>
      </Grid>
    </Stack>
  )
}
