import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'

export const Auth0ProviderWithNavigate = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate()
  const onRedirectCallback = (appState: AppState | undefined): void => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK,
        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
