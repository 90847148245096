import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import * as React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Auth0ProviderWithNavigate } from './Auth/Auth0ProviderWithNavigate'
import { ErrorBoundary } from './ErrorBoundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const theme = extendTheme({
  colors: {
    hw: {
      100: '#EDD273',
      200: '#382048',
      300: '#3D4E49',
      400: '#F0B045',
      500: '#8680A5',
      600: '#599861',
      700: '#232020',
      800: '#E3E4E3',
      900: '#FFFFFF',
      1000: '#212121',
      1100: '#333333'
    }
  },
  styles: {
    global: {
      body: {
        color: '#FFFFFF',
        background: '#181818',
        fontFamily: 'Montserrat,sans-serif',
        fontSize: '1rem'
      }
    }
  },
  fonts: {
    body: 'Montserrat,sans-serif',
    heading: 'Montserrat,sans-serif'
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
})

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <App />
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraProvider>

  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
