import React from 'react'
import './CarbonFreeScore.css'
import {
  Flex,
  Grid,
  Heading,
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { grey, valueToColor } from '../../Util'
import { Gauge } from './Guage'
import { DashboardData } from '../DashboardSelector/DataUtil'
import { InfoButton } from '../../SharedComponents/InfoButton'

interface CarbonFreeScoreProps {
  dashboardData: DashboardData
  infoText: React.ReactElement
}

export const CarbonFreeScore = ({ dashboardData, infoText }: CarbonFreeScoreProps) => {
  const score = Math.round((dashboardData.carbonFreeScore) - (dashboardData.gridAverage))
  const vsGridAvg = dashboardData.carbonFreeScore === 0
    ? <Heading size='md'>--</Heading>
    : score >= 0
      ? <Heading size='md'><Flex gap={2}>+{score}% <Image src='/icons/chevronup.svg' /></Flex></Heading>
      : <Heading size='md'><Flex gap={2}>{score}% <Image src='/icons/chevrondown.svg' /></Flex></Heading>
  return (
    <Grid className='carbon-free-score'>
      <Grid bg='#212121' gridRowEnd='span 2' templateColumns='60% auto'
        p={3} borderRadius={5} h='100%'>
        <Gauge value={dashboardData.carbonFreeScore} maxValue={100} units='%'
          fill={valueToColor(dashboardData.carbonFreeScore, 100)}>
          <foreignObject x='0' y='0' width='100%' height='100%'>
            <Stack h='100%' justifyContent='center' alignItems='center'>
              {dashboardData.carbonFreeScore > 0
                ? <Heading size='lg' color={grey(87)}>
                  {`${Math.round(Math.min(dashboardData.carbonFreeScore, 100))}%`}
                </Heading>
                : <Image src='/icons/nousage.svg' h={35} />
              }
              <Text fontSize={14} fontWeight='semibold' color={grey(60)}>
                {dashboardData.carbonFreeScore > 0 ? 'CARBON-FREE' : 'NO USAGE'}
              </Text>
              <InfoButton buttonSize={18} buttonId='carbonfreescoreinfo' infoText={infoText}
              />
            </Stack>
          </foreignObject>
        </Gauge>
        <Stack justifyContent='center' alignItems='center'>
          {vsGridAvg}
          <Text fontSize={14} color={grey(60)} fontWeight='semibold'>VS GRID AVERAGE</Text>
          {score > 0 && <Image src='/icons/thumbsup.svg' h={7} />}
        </Stack>
      </Grid>
      <Stack justifyContent='center' alignItems='center' bg='#212121' borderRadius={5} h='100%'>
        <Heading size='md'>{dashboardData.totalUsage.toFixed(0)}</Heading>
        <Text fontSize='xs' color={grey(60)}>kWh</Text>
        <Text fontSize='sm' fontWeight='bold'>USAGE</Text>
      </Stack>
      <Stack justifyContent='center' alignItems='center' bg='#212121' borderRadius={5} h='100%'>
        <Heading size='md'>{(dashboardData.totalEmissions / 1000).toFixed(0)}</Heading>
        <Text fontSize='xs' color={grey(60)}>kgCO<sub>2</sub>e</Text>
        <Text fontSize='sm' fontWeight='bold'>EMISSIONS</Text>
      </Stack>
    </Grid >
  )
}
