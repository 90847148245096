import React from 'react'
import {
  Box, Center,
  Grid,
  useRadio,
  useRadioGroup,
  UseRadioProps
} from '@chakra-ui/react'

import './SharedComponents.css'
import { grey } from '../Util'

interface RadioCardProps {
  radioProps: UseRadioProps
  value: string | RadioOption
  isDisabled: boolean
  buttonPadding?: number | string
}
const RadioCard = ({ radioProps, value, isDisabled, buttonPadding = 3 }: RadioCardProps) => {
  const { getInputProps, getRadioProps } = useRadio(radioProps)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as='label'>
      {!isDisabled && <input {...input} />}
      <Center
        {...checkbox}
        className={isDisabled ? 'radio-button-option-disabled' : 'radio-button-option'}
        p={buttonPadding}
        _checked={{
          bg: grey(24),
          color: grey(87)
        }}
        _hover={!isDisabled
          ? { bg: grey(30), color: '#232020' }
          : {}}
      >
        {typeof value === 'string' ? value : value.buttonElement}
      </Center>
    </Box>
  )
}

interface RadioButtonProps {
  updateChoice: (choice: string) => void
  options: string[] | RadioOption[]
  defaultValue: string | RadioOption
  name: string
  isDisabled?: boolean
  width?: number
  buttonPadding?: number | string
}

export interface RadioOption {
  name: string
  buttonElement: React.ReactElement
}

export const RadioButton = (props: RadioButtonProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    defaultValue: typeof props.defaultValue === 'string' ? props.defaultValue : props.defaultValue.name,
    onChange: props.updateChoice,
    isDisabled: props.isDisabled
  })

  const group = getRootProps()

  return (
    <Grid {...group} className='radio-button' bg={grey(5)} w={props.width || 600}
      templateColumns={`repeat(${props.options.length}, 1fr)`}>
      {props.options.map((value) => {
        const stringValue = typeof value === 'string' ? value : value.name
        return (
          <RadioCard key={stringValue}
            value={value}
            radioProps={getRadioProps({ value: stringValue })}
            isDisabled={props.isDisabled ? props.isDisabled : false}
            buttonPadding={props.buttonPadding}
          />
        )
      })}
    </Grid>
  )
}
