import React, { useState } from 'react'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalBody, Text, Stack, Grid, Button,
  useToast
} from '@chakra-ui/react'
import { ReactMultiEmail } from 'react-multi-email'
import { useMutation } from '@tanstack/react-query'
import { copyLink, generateInviteUrl, grey } from '../Util'
import { Snackbar } from '../SharedComponents/Snackbar'
import { Send } from 'react-feather'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { sendEmails } from '../Api/Api'
import { useAuth0 } from '@auth0/auth0-react'

interface SendLinkModalProps {
  isOpen: boolean
  onClose: () => void
  connection: MercuryConnection | null
  emailTemplate: 'invite' | 'askforhelp'
}

export const SendLinkModal = (props: SendLinkModalProps) => {
  const [teamMembers, setTeamMembers] = useState<string[]>([])
  const [copyLinkText, setCopyLinkText] = useState<string>('Copy link')
  const [linkSending, setLinkSending] = useState<boolean>(false)

  const toast = useToast()
  const auth0 = useAuth0()

  const inviteTeamMembers = useMutation(async () => {
    if (teamMembers.length > 0 && props.connection) {
      setLinkSending(true)
      await sendEmails(props.connection.mercuryOrganization.id, teamMembers, props.emailTemplate, auth0)
      setLinkSending(false)
      toast({
        position: 'top-right',
        render: () => <Snackbar text="Request sent" icon={<Send />} />
      })
    }
    props.onClose()
  })

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='3xl'>
      <ModalContent className='connect-modal'>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={5} p={3}>
            <Stack gap={7} pb={10}>
              {props.emailTemplate === 'askforhelp'
                ? <Text fontSize='xl'><b>Ask a teammate to connect {props.connection?.accountName} to Mercury</b></Text>
                : <Text fontSize='xl'><b>Invite teammates to join {props.connection?.accountName} on Mercury</b></Text>
              }
              <Stack>
                <Text fontSize='xs' color={grey(60)}>
                  Ask teammates by email.
                </Text>
                <ReactMultiEmail
                  className='step2Email'
                  inputClassName='step2EmailInput'
                  placeholder='name@organization.com, name@organization.com,...'
                  emails={teamMembers}
                  onChange={(emails: string[]) => {
                    setTeamMembers(emails)
                  }}
                  autoFocus={true}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span data-tag-handle onClick={() => { removeEmail(index) }}>
                          ×
                        </span>
                      </div>
                    )
                  }}
                />
              </Stack>
            </Stack>
            <Grid templateColumns="16rem 10rem" gap={3}>
              <Button
                onClick={() => { inviteTeamMembers.mutate() }}
                color="#181818"
                isLoading={linkSending}
                isDisabled={teamMembers.length === 0}
                _hover={{ background: grey(60) }}
              >
                Send
              </Button>
              <Button onClick={() => {
                copyLink(generateInviteUrl(props.connection?.mercuryOrganization.id || ''), () => {
                  setCopyLinkText('Copied!')
                  setTimeout(() => { setCopyLinkText('Copy link') }, 1000)
                })
              }} variant="link" color={grey(60)}>
                <b>{copyLinkText}</b>
              </Button>
            </Grid>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
