import React, { useEffect, useState } from 'react'

import { MercuryMap } from '../Dashboard/Map/MercuryMap'
import {
  getDemoData,
  QueryInterval
} from '../Api/Api'
import { Header } from '../Header/Header'
import { Box, Button, Grid, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { CarbonFreeScore } from '../Dashboard/CarbonFreeScore/CarbonFreeScore'
import { DashboardSelector } from '../Dashboard/DashboardSelector/DashboardSelector'
import { Charts } from '../Dashboard/Charts/Charts'
import { grey } from '../Util'
import { DashboardData } from '../Dashboard/DashboardSelector/DataUtil'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { useAuth0 } from '@auth0/auth0-react'
import { CloudProviderSelector, SelectedProviders } from '../Header/CloudProviderSelector'
import { StatusIndicator } from '../Header/StatusIndicator'

export const Demo = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    carbonFreeScore: 0,
    gridAverage: 0,
    totalUsage: 0,
    totalEmissions: 0,
    rawData: []
  })
  const [showProviders, setShowProviders] = useState<SelectedProviders>({ AWS: true, Azure: true, GCP: false })
  const [selectedZone, setSelectedZone] = useState<string>()
  const [connections, setConnections] = useState<MercuryConnection[]>([])
  const auth0 = useAuth0()

  // const navigate = useNavigate()

  const {
    isLoading: queryLoading,
    data: queryData
  } = useQuery({
    queryKey: ['data', QueryInterval.RECENT],
    queryFn: async () => await getDemoData(QueryInterval.RECENT),
    refetchInterval: (30 * 60 * 1000) // refetch every 30 min
  })

  useEffect(() => {
    if (queryData?.connectionData) {
      setConnections(Object.values(queryData.connectionData).map(c => c.connection))
    }
  }, [queryData])

  const login = async () => {
    await auth0.loginWithRedirect()
  }

  return (
    <Grid templateRows='8.5vh 91.5vh'>
      <Header>
        <Grid templateColumns='auto 1fr auto auto' gap={5} alignItems='center' w='100%'>
          <Skeleton isLoaded={!queryLoading} startColor={grey(12)}
            endColor={grey(24)} w='100%' h='100%'>
            {connections.length > 0 && queryData
              ? < StatusIndicator asOf={new Date(Math.max(
                ...queryData.connectionData[connections[0].id].rawData.map(d => d.timestamp.getTime())
              ))} />
              : <></>
            }
          </Skeleton>
          <Box justifySelf='center'>
            <CloudProviderSelector showProviders={showProviders} setShowProviders={setShowProviders} />
          </Box>
          <Button bg={grey(0)} color={grey(87)} _hover={{ bg: grey(24) }} w={115}>
            <Text fontSize='sm' onClick={login}>SIGN IN</Text>
          </Button>
          <Button bg={`linear-gradient(90deg, rgba(237, 210, 115, 0.87) -10.4%, ${grey(76)} 173%)`}
            color='#181818' fontSize={14} h={8} _hover={{
              bg: `linear-gradient(90deg, rgba(237, 210, 115, 1) -10.4%, ${grey(100)} 173%)`
            }} onClick={login}>
            CONNECT MY CLOUD
          </Button>
        </Grid>
      </Header>
      <Grid templateRows='50% 50%' templateColumns='50% 50%' borderTop={`1px solid ${grey(12)}`}
        borderLeft={`1px solid ${grey(12)}`}>
        <Skeleton isLoaded={!queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={queryLoading ? 6 : 0}>
          {queryData &&
            <DashboardSelector connections={connections} queryData={queryData} disableConnections={true}
              showProviders={showProviders} setDashboardData={setDashboardData} selectedZone={selectedZone}
              setSelectedZone={setSelectedZone} />
          }
        </Skeleton>

        <Skeleton isLoaded={!queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={queryLoading ? 6 : 0}>
          <CarbonFreeScore dashboardData={dashboardData} infoText={
            <Stack p={5} gap={3}>
              <Text>
                Welcome to Mercury!
              </Text>
              <Text>
                Connect your cloud to view your usage data and get your Carbon-Free Score.
                Connecting to Mercury is free and easy—it just tales a few clicks.
              </Text>
              <Text>
                Not ready to connect? No problem. You can still view our 24/7 location-specific emissions data.
                Our data is sourced from local grid balancing authorities. It is consumption-based
                (meaning we account for regional electricity imports and exports) and complete
                (we use AI to fill in any gaps), giving you the most up-to-date and comprehensive view of
                the carbon intensity of the cloud.
              </Text>
            </Stack>
          } />
        </Skeleton>

        <Skeleton isLoaded={!queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={queryLoading ? 6 : 0}>
          <Charts data={dashboardData} />
        </Skeleton>

        <Skeleton isLoaded={!queryLoading} startColor={grey(12)} endColor={grey(24)}
          m={queryLoading ? 6 : 0}>
          {queryData &&
            <MercuryMap queryLoading={queryLoading} gridAverages={queryData.gridAverages}
              dashboardData={dashboardData} setSelectedZone={setSelectedZone} showProviders={showProviders} />
          }
        </Skeleton>
      </Grid>
    </Grid>
  )
}
