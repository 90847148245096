import React, { useEffect, useState } from 'react'
import { Grid, Text } from '@chakra-ui/react'
import { format } from 'date-fns-tz'
import { green, grey } from '../Util'

interface StatusIndicatorProps {
  asOf: Date
}

export const StatusIndicator = (props: StatusIndicatorProps) => {
  const [tick, setTick] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      if (!document.hidden) {
        setTick((tick) => tick + 1)
      }
    }, 60)
    return () => { clearInterval(interval) }
  }, [])

  const pulseStyle = {
    stroke: grey(60),
    fill: 'rgba(0,0,0,0)'
  }

  const pulseRadius = 20 + (tick % 30)

  const dtFormat = format(props.asOf, 'MMM d, KK:mm aaaa zzz')
  return (
    <Grid templateColumns='auto auto' alignItems='center' justifyItems='center' bg={grey(5)}
      borderRadius={100} w={325} h={10} p='0 1rem'>
      <svg height={20} width={20} style={{ marginBottom: '0.1rem' }}>
        <circle cx="50%" cy="50%" r="20%" style={{
          fill: green,
          stroke: green
        }} />
        <circle cx="50%" cy="50%" r={`${pulseRadius}%`} style={pulseStyle} />
      </svg>
      <Text fontSize='sm' color={grey(60)}>Last Updated {dtFormat}</Text>
    </Grid>
  )
}
