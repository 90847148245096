import axios from 'axios'
import { Auth0ContextInterface } from '@auth0/auth0-react'
import { startOfHour } from 'date-fns'
import {
  DataPoint,
  MercuryConnection,
  MercuryDataPoint,
  MercuryUser
} from '@headway-cooperative/project-mercury-utils'

export interface DashboardDataResponse {
  connectionData: Record<string, ConnectionData>
  gridAverages: Record<string, number>
}

export interface ConnectionData {
  connection: MercuryConnection
  carbonFreeScore: number
  gridAverage: number
  totalUsage: number
  totalEmissions: number
  rawData: MercuryDataPoint[]
}

export enum QueryInterval {
  RECENT = 'Recent',
  THIRTYDAYS = '30Days',
  YEARTODATE = 'Year-to-date'
}

const API_URL = '/api'

export const parseTS = <T extends DataPoint>(data: T): T => {
  return {
    ...data,
    timestamp: new Date(data.timestamp)
  }
}

const getQueryParamsForInterval = (interval: QueryInterval): object => {
  const now = new Date()
  if (interval === QueryInterval.RECENT) {
    return {
      window: '1 hour',
      end: startOfHour(now).toISOString()
    }
  } else if (interval === QueryInterval.YEARTODATE) {
    return {
      window: '1 month',
      end: startOfHour(now).toISOString()
    }
  } else if (interval === QueryInterval.THIRTYDAYS) {
    return {
      window: '1 day',
      end: startOfHour(now).toISOString()
    }
  }
  return {}
}

export const getData = async (
  interval: QueryInterval,
  auth0: Auth0ContextInterface
): Promise<DashboardDataResponse> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({
    ...getQueryParamsForInterval(interval)
  })
  const requestConfig = { headers: { Authorization: `Bearer ${accessToken}` } }

  const dataUrl = `${API_URL}/dashboardData?${params.toString()}`
  const resp = await axios.get<DashboardDataResponse>(dataUrl, requestConfig)
  Object.keys(resp.data.connectionData).forEach(connId => {
    resp.data.connectionData[connId].rawData = resp.data.connectionData[connId].rawData.map(parseTS)
  })
  return resp.data
}

export const getDemoData = async (
  interval: QueryInterval
): Promise<DashboardDataResponse> => {
  const params = new URLSearchParams({
    ...getQueryParamsForInterval(interval)
  })

  const dataUrl = `${API_URL}/demoData?${params.toString()}`
  const resp = await axios.get<DashboardDataResponse>(dataUrl)
  Object.keys(resp.data.connectionData).forEach(connId => {
    resp.data.connectionData[connId].rawData = resp.data.connectionData[connId].rawData.map(parseTS)
  })
  return resp.data
}

export const signup = async (
  orgName: string,
  auth0: Auth0ContextInterface
): Promise<MercuryUser> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({ orgName })
  const url = `${API_URL}/signup?${params.toString()}`
  const resp = await axios.post(url, {}, { headers: { Authorization: `Bearer ${accessToken}` } })
  return resp.data as MercuryUser
}

export const createConnection = async (
  orgId: string,
  accountName: string,
  cloudProvider: 'AWS' | 'GCP' | 'Azure',
  auth0: Auth0ContextInterface
): Promise<MercuryConnection> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({ orgId, accountName, cloudProvider })
  const url = `${API_URL}/connection?${params.toString()}`
  const resp = await axios.post(url, {}, { headers: { Authorization: `Bearer ${accessToken}` } })
  return resp.data as MercuryConnection
}

export const deleteConnection = async (
  connection: MercuryConnection,
  auth0: Auth0ContextInterface
): Promise<void> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({ connectionId: connection.id })
  const url = `${API_URL}/connection?${params.toString()}`
  await axios.delete(url, { headers: { Authorization: `Bearer ${accessToken}` } })
}

export const getConnectionsForUser = async (auth0: Auth0ContextInterface): Promise<MercuryConnection[]> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const url = `${API_URL}/connection`
  const resp = await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
  return resp.data.map((c: MercuryConnection) => {
    c.lastRetrieval = new Date(c.lastRetrieval)
    return c
  }) as MercuryConnection[]
}

export const getUserInfo = async (auth0: Auth0ContextInterface): Promise<MercuryUser> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const url = `${API_URL}/userInfo`
  const resp = await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
  return resp.data as MercuryUser
}

export const addUserToOrg = async (
  inviteToken: string,
  auth0: Auth0ContextInterface
): Promise<MercuryUser> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({ token: inviteToken })
  const url = `${API_URL}/addUserToOrg?${params.toString()}`
  const resp = await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
  return resp.data as MercuryUser
}

export const sendEmails = async (
  orgId: string,
  emails: string[],
  emailTemplate: 'invite' | 'askforhelp',
  auth0: Auth0ContextInterface
): Promise<void> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({ orgId, template: emailTemplate })
  emails.forEach(e => { params.append('email', e) })
  const url = `${API_URL}/sendEmail?${params.toString()}`
  await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
}

export const updateSelectedTags = async (
  connectionId: string,
  tags: string[],
  auth0: Auth0ContextInterface
): Promise<void> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const params = new URLSearchParams({ connectionId })
  tags.forEach(t => { params.append('tags', t) })
  const url = `${API_URL}/setSelectedTags?${params.toString()}`
  await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
}

export const getReingestJobs = async (
  auth0: Auth0ContextInterface
): Promise<{ connectionIds: string[] }> => {
  const accessToken = await auth0.getAccessTokenSilently()
  const url = `${API_URL}/getReingestJobs`
  const resp = await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } })
  return resp.data as { connectionIds: string[] }
}
