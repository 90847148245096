import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteConnection, getConnectionsForUser } from '../Api/Api'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Grid,
  Heading,
  Menu, MenuButton, MenuItem, MenuList,
  Card,
  CardBody,
  Image,
  Badge,
  Text, Button, Spacer, IconButton,
  useDisclosure, Flex, Progress
} from '@chakra-ui/react'
import { MoreHorizontal, Plus, Trash2 } from 'react-feather'
import { LoadingSpinner } from '../SharedComponents/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import { Header } from '../Header/Header'
import './Connections.css'
import { ConnectModal } from './ConnectModal'
import { grey } from '../Util'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'

export const Connections = () => {
  const [connections, setConnections] = useState<MercuryConnection[]>([])
  const [selectedConnection, setSelectedConnection] = useState<MercuryConnection | null>(null)
  const auth0 = useAuth0()
  const navigate = useNavigate()
  const {
    isLoading: connectionsLoading,
    data: connectionsData,
    error: connectionsError
  } = useQuery({
    queryKey: ['connections'],
    queryFn: async () => await getConnectionsForUser(auth0),
    refetchOnMount: false
  })

  useEffect(() => {
    if (!connectionsLoading && connectionsData) {
      setConnections(connectionsData)
      if (connectionsData.length === 0) {
        navigate('/onboarding')
      }
    }
  }, [connectionsLoading, connectionsData])

  const queryClient = useQueryClient()

  const deleteConnectionMutation = useMutation(async (connection: MercuryConnection) => {
    await deleteConnection(connection, auth0)
    await queryClient.invalidateQueries({ queryKey: ['connections'] })
  }, {
    onError: (error: Error) => {
      throw new Error(error.message)
    }
  })

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()

  if (connectionsError) {
    throw new Error((connectionsError as Error).message)
  }

  const getCardContents = (connection: MercuryConnection) => {
    switch (connection.connectionStatus) {
      case 'CONNECTING':
        return (
          <>
            <Badge borderRadius={100} lineHeight="1.5rem" p={1} w={150} h={30} textAlign="center">
              {connection.connectionStatus}
            </Badge>
            <Text fontSize="sm" color={grey(60)}>
              This can take up to a few hours. We'll notify you by email when {connection.accountName} is live
            </Text>
          </>
        )
      case 'CONNECTED':
        return (
          <Grid templateColumns="auto 1fr" justifyItems="start" alignItems="start" gap={4}>
            <Badge borderRadius={100} lineHeight="1.5rem" p={1} w={150} h={30} textAlign="center">
              {connection.connectionStatus}
            </Badge>
            <Button variant="link" as="a" href="/dashboard" color={grey(87)} mt={1.5}>
              <u>Go to Dashboard</u>
            </Button>
          </Grid>
        )
      case 'NOT CONNECTED':
      default:
        return (
          <Grid templateColumns="auto 1fr" justifyItems="start" alignItems="start" gap={4}>
            <Badge borderRadius={100} lineHeight="1.5rem" p={1} w={150} h={30} textAlign="center">
              {connection.connectionStatus}
            </Badge>
            <Button variant="link" color={grey(87)} mt={1.5} onClick={() => {
              setSelectedConnection(connection)
              onModalOpen()
            }}>
              Connect to Mercury
            </Button>
          </Grid>
        )
    }
  }

  return (
    <Grid templateRows='10vh 90vh'>
      <Header />
      {!connectionsLoading && connections.length > 0
        ? <Grid w="100%" p={10} gap={10} borderTop={`1px solid ${grey(12)}`} templateColumns="1fr 1fr 1fr">
          {connections.map(connection =>
            <Card bg={grey(5)} h={250} key={connection.id}>
              {connection.connectionStatus === 'CONNECTING'
                ? <Progress size='xs' colorScheme='whatsapp' isIndeterminate className='connecting-progress' />
                : <div className={`connection-${connection.connectionStatus.split(' ').join('-').toLowerCase()}`} />}
              <CardBody>
                <Grid gap={5} templateRows="auto 1fr auto" h="100%">
                  <Grid templateColumns="1fr auto">
                    <Heading color={grey(87)} size="md">{connection.accountName}</Heading>
                    <Menu>
                      <MenuButton color={grey(38)}
                        bg={grey(5)}
                        borderRadius={100}
                        as={Button}
                        p={2}
                        isDisabled={connection.connectionStatus !== 'CONNECTED'}
                        _hover={{ bg: grey(8) }}
                      >
                        <MoreHorizontal />
                      </MenuButton>
                      <MenuList bg='#212121' border={`1px solid ${grey(24)}`} p={0}>
                        <MenuItem onClick={() => { deleteConnectionMutation.mutate(connection) }}
                          justifyContent='center' bg={grey(12)} _hover={{ bg: grey(24) }}>
                          <Flex gap={2} color={grey(87)}>
                            <Trash2 />
                            <Text fontSize='sm' >Delete connection</Text>
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Grid>
                  {getCardContents(connection)}
                  <Grid templateColumns="1fr auto">
                    <Spacer />
                    <Image src={`/icons/${connection.cloudProvider.toLowerCase()}.svg`} height={5} />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          )}
          <IconButton aria-label='add-connection' isDisabled={true} isRound={true} icon={<Plus />} w={14} h={14}
            color={grey(38)} bg={grey(5)} _hover={{ bg: grey(8) }} />
          <ConnectModal isOpen={isModalOpen} onClose={onModalClose} connection={selectedConnection} />
        </Grid>
        : <LoadingSpinner />
      }
    </Grid>
  )
}
