import Map, { NavigationControl, useMap } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useState } from 'react'
import { AvailabilityZone, grey, groupData } from '../../Util'
import { ZoneMarker } from './ZoneMarker'
import GeoJSON from 'geojson'
import DataCentersJSON from '../../assets/geojson.json'
import { MapLegend } from './MapLegend'
import { Progress, Stack } from '@chakra-ui/react'
import './MercuryMap.css'
import { HoverInfo, MapHover } from './MapHover'
import { Tooltip } from 'react-tooltip'
import { MercuryDataPoint } from '@headway-cooperative/project-mercury-utils'
import { DashboardData } from '../DashboardSelector/DataUtil'
import { SelectedProviders } from '../../Header/CloudProviderSelector'

interface MercuryMapProps {
  gridAverages: Record<string, number>
  dashboardData: DashboardData
  queryLoading: boolean
  setSelectedZone: (zone?: string) => void
  showProviders: SelectedProviders
}

export const MercuryMap = ({
  queryLoading,
  gridAverages,
  dashboardData,
  setSelectedZone,
  showProviders
}: MercuryMapProps) => {
  const [groupedData, setGroupedData] = useState<MercuryDataPoint[]>([])
  const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null)

  const dataCenters = DataCentersJSON as GeoJSON.FeatureCollection<GeoJSON.Geometry>

  const getMarkers = () => {
    return dataCenters.features.filter(feature => {
      const zone = feature.properties as AvailabilityZone
      if (showProviders) {
        return showProviders[zone.provider]
      }
      return true
    }).map((feature, index) => {
      const zone = feature.properties as AvailabilityZone
      const key = `${zone.provider}<|>${zone.azName}`
      const gridAverage = gridAverages[key]
      const selected = dashboardData.rawData.filter(d => (
        d.availabilityZone === zone.azName && (dashboardData.totalUsage === 0 || d.kWh > 0)
      )).length > 0
      return <ZoneMarker
        key={`marker-${index}`}
        availabilityZone={zone}
        onClick={() => {
          setSelectedZone(zone.azName)
        }}
        gridAverage={gridAverage || 0}
        selected={selected}
        cloudProvider={zone.provider}
        visible={gridAverage !== undefined}
        setHoverInfo={setHoverInfo}
      />
    })
  }

  const [markers, setMarkers] = useState<React.ReactElement[]>(getMarkers())

  useEffect(() => {
    setGroupedData(groupData(dashboardData.rawData, ['timestamp', 'availabilityZone']))
  }, [dashboardData])

  useEffect(() => {
    setMarkers(getMarkers())
  }, [groupedData, showProviders])

  return <Stack w='100%' h='100%'>
    {queryLoading && <Progress size='xs' isIndeterminate colorScheme='gray' borderRadius={10} />}
    <Map
      initialViewState={{
        longitude: -98.5,
        latitude: 40,
        zoom: 3
      }}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
      mapStyle="mapbox://styles/mapbox/dark-v11"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      cursor="default"
    >
      <MapResizeHandler queryLoading={queryLoading} />
      <NavigationControl position="top-right" />
      {markers}
      <MapLegend />
      <Tooltip anchorSelect='.map-marker' border={`1px solid ${grey(24)}`}>
        <MapHover hoverInfo={hoverInfo} />
      </Tooltip>
    </Map>
  </Stack>
}

const MapResizeHandler = (props: { queryLoading: boolean }) => {
  const map = useMap()
  useEffect(() => {
    if (map.current) {
      map.current.getMap()?.resize()
    }
  }, [props.queryLoading])
  return <></>
}
