import React from 'react'
import { Button, Grid, Image, Stack, Text, useToast } from '@chakra-ui/react'
import { RadioButton } from '../../SharedComponents/RadioButton'
import { Snackbar } from '../../SharedComponents/Snackbar'
import { grey } from '../../Util'

interface Step3Props {
  orgName: string
  cloudProvider: 'AWS' | 'GCP' | 'Azure'
  setCloudProvider: (cloudProvider: 'AWS' | 'GCP' | 'Azure') => void
  createConnection: () => void
  isCreateConnectionLoading: boolean
}

export const Step3 = (props: Step3Props) => {
  const toast = useToast()
  const providers = [
    {
      name: 'AWS',
      buttonElement: <Image src='/icons/aws.svg' h={18} />
    },
    {
      name: 'Azure',
      buttonElement: <Image src='/icons/azure.svg' h={18} />
    },
    {
      name: 'GCP',
      buttonElement: <Image src='/icons/gcp.svg' h={18} />
    }
  ]
  return (
    <Stack gap={5} p={10}>
      <Text fontSize="sm" color={grey(60)}>Step 3 of 4</Text>
      <Stack gap={7} pb={10}>
        <Text fontSize='2xl'>Which cloud provider does {props.orgName} use?</Text>
        <Stack>
          <Text fontSize='xs' color={grey(60)}>
            Azure and GCP are in the works.
            Join our waitlist and we’ll reach out as soon as they are live.
          </Text>
          <RadioButton
            options={providers}
            defaultValue={providers[0]}
            updateChoice={(choice) => { props.setCloudProvider(choice as 'AWS' | 'GCP' | 'Azure') }}
            isDisabled={true}
            name='onboarding-select-provider'
          />
        </Stack>
      </Stack>
      <Grid templateColumns="16rem 10rem" gap={3}>
        <Button
          onClick={props.createConnection}
          color="#181818"
          _hover={{
            background: grey(60)
          }}
          isLoading={props.isCreateConnectionLoading}
        >
          Next
        </Button>
        <Button onClick={() => {
          toast({
            position: 'top-right',
            render: () => <Snackbar text="Added to waitlist" />
          })
        }} variant="link" color={grey(60)}>
          <b>Join our waitlist</b>
        </Button>
      </Grid>
    </Stack>

  )
}
