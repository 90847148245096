import React from 'react'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody, useDisclosure
} from '@chakra-ui/react'
import { Step4 } from '../Onboarding/OnboardingSteps/Step4'
import { SendLinkModal } from './SendLinkModal'
import './ConnectModal.css'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'

interface ConnectModalProps {
  isOpen: boolean
  onClose: () => void
  connection: MercuryConnection | null
}

export const ConnectModal = (props: ConnectModalProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size='3xl'>
        <ModalOverlay className='connect-modal-overlay' />
        <ModalContent className='connect-modal'>
          <ModalCloseButton />
          <ModalBody>
            <Step4 connection={props.connection} hideStep={true} openSendLinkModal={onModalOpen} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <SendLinkModal isOpen={isModalOpen} onClose={() => {
        onModalClose()
        props.onClose()
      }} connection={props.connection} emailTemplate='askforhelp' />
    </>
  )
}
