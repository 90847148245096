import React, { useState } from 'react'
import { Box, Grid, Heading, useDisclosure } from '@chakra-ui/react'
import 'react-multi-email/dist/style.css'
import { createConnection, sendEmails, signup } from '../Api/Api'
import { Step1 } from './OnboardingSteps/Step1'
import { Step2 } from './OnboardingSteps/Step2'
import { Step3 } from './OnboardingSteps/Step3'
import { Step4 } from './OnboardingSteps/Step4'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { Header } from '../Header/Header'
import { SendLinkModal } from '../Connections/SendLinkModal'
import { grey } from '../Util'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'

export const Onboarding = () => {
  const [step, setStep] = useState<number>(0)
  const [orgName, setOrgName] = useState<string>('')
  const [teamMembers, setTeamMembers] = useState<string[]>([])
  const [cloudProvider, setCloudProvider] = useState<'AWS' | 'GCP' | 'Azure'>('AWS')
  const [isCreateConnectionLoading, setIsCreateConnectionLoading] = useState<boolean>(false)
  const [connection, setConnection] = useState<MercuryConnection | null>(null)

  const auth0 = useAuth0()
  const queryClient = useQueryClient()

  const newConnection = useMutation(async () => {
    setIsCreateConnectionLoading(true)
    const user = await signup(orgName, auth0)
    const newOrg = user.mercuryOrganizations.find(o => o.name === orgName)!
    setConnection(await createConnection(newOrg.id, `${orgName} ${cloudProvider}`, cloudProvider, auth0))
    if (teamMembers.length > 0) {
      await sendEmails(newOrg.id, teamMembers, 'invite', auth0)
    }
    await queryClient.invalidateQueries({ queryKey: ['connections'] })
    setStep(step + 1)
    setIsCreateConnectionLoading(false)
  }, {
    onError: (error: Error) => {
      setIsCreateConnectionLoading(false)
      throw new Error(error.message)
    }
  })

  const advanceStep = () => {
    setStep(step + 1)
  }

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()

  const steps: React.ReactElement[] = [
    <Step1 setOrgName={setOrgName} advanceStep={advanceStep} />,
    <Step2 orgName={orgName} teamMembers={teamMembers} setTeamMembers={setTeamMembers} advanceStep={advanceStep} />,
    <Step3 orgName={orgName} cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
      createConnection={newConnection.mutate} isCreateConnectionLoading={isCreateConnectionLoading} />,
    <Step4 connection={connection} openSendLinkModal={onModalOpen} />
  ]

  return (
    <Grid templateRows='10vh 90vh'>
      <Header children={orgName ? <Heading>{orgName}</Heading> : <></>} />
      <Box borderTop={`1px solid ${grey(12)}`} borderLeft={`1px solid ${grey(12)}`}>
        {steps[step]}
      </Box>
      <SendLinkModal isOpen={isModalOpen} onClose={onModalClose} connection={connection} emailTemplate='askforhelp' />
    </Grid>
  )
}
