import React from 'react'
import { Flex } from '@chakra-ui/react'
import { grey } from '../Util'

interface SnackbarProps {
  icon?: React.ReactElement
  text: string
}

export const Snackbar = (props: SnackbarProps) => {
  return (
    <Flex color='#181818' p={3} bg={grey(87)} borderRadius={5} gap={2}>
      {props.icon && props.icon}
      {props.text}
    </Flex>
  )
}
