import React from 'react'
import { Grid, Text } from '@chakra-ui/react'
import { TooltipProps } from 'recharts'
import { format as dateFormat } from 'date-fns-tz'
import { grey } from '../../Util'

export const CustomTooltip = ({ active, payload, label, labelFormatter }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <Grid bg="#212121" borderRadius={5} border={`1px solid ${grey(12)}`} p={2} templateColumns='auto auto' gap={2}>
        {labelFormatter?.(label, payload)}
        <Text fontSize="xs">{dateFormat(new Date(label), 'eeee, MMM d, KK:mm aaaa zzz')}</Text>
      </Grid>
    )
  }

  return null
}
