import React, { useEffect, useState } from 'react'
import './DashboardSelector.css'
import { Flex, Grid, Image, Text, useDisclosure } from '@chakra-ui/react'
import { DashboardDataResponse } from '../../Api/Api'
import { grey } from '../../Util'
import { DashboardData, aggregateDashboardData } from './DataUtil'
import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { SelectedProviders } from '../../Header/CloudProviderSelector'
import {
  DashboardOptionSelector,
  DashboardSelectorOption,
  TagSelection
} from './DashboardOptionSelector'
import { DashboardGroupSelector, DashboardSelectorGroup } from './DashboardGroupSelector'
import { AddTagsModal } from './AddTagsModal'
import { DashboardSelectorSort, FilterAs, SortBy } from './DashboardSelectorSort'
import { UsageFacet, UsageFacetSwitch } from '../../SharedComponents/UsageFacetSwitch'
import { getCloudOptions, getMyCloudsGroup } from './DashboardSelectorMyClouds'
import { getRegionOptions, getRegionsGroup } from './DashboardSelectorRegions'
import { getTagDataFilter, getTagOptions, getTagsGroup } from './DashboardSelectorTags'

interface DashboardSelectorProps {
  connections: MercuryConnection[]
  queryData: DashboardDataResponse
  setDashboardData: (data: DashboardData) => void
  selectedZone?: string
  setSelectedZone: (zone?: string) => void
  disableConnections?: boolean
  showProviders: SelectedProviders
}

export const DashboardSelector = (props: DashboardSelectorProps) => {
  const [cloudOptions, setCloudOptions] = useState<DashboardSelectorOption[]>([])
  const [regionOptions, setRegionOptions] = useState<DashboardSelectorOption[]>([])
  const [tagOptions, setTagOptions] = useState<DashboardSelectorOption[]>([])
  const [currentOptions, setCurrentOptions] = useState<DashboardSelectorOption[]>([])
  const [selectedGroup, setSelectedGroup] = useState<DashboardSelectorGroup>()
  const [selectedOption, setSelectedOption] = useState<DashboardSelectorOption>()
  const [selectedTags, setSelectedTags] = useState<TagSelection[]>([])
  const [connectionForTagsModal, setConnectionForTagsModal] = useState<MercuryConnection>()
  const [usageFacet, setUsageFacet] = useState<UsageFacet>('carbonfree')
  const [sortBy, setSortBy] = useState<SortBy>('name')
  const [filterAs, setFilterAs] = useState<FilterAs>('intersection')

  useEffect(() => {
    // Update the list of groups and options when query returns
    setCloudOptions(getCloudOptions(props.connections, props.queryData, props.showProviders))
    setRegionOptions(getRegionOptions(
      props.connections, props.queryData, props.showProviders, props.disableConnections
    ))
    setTagOptions(getTagOptions(props.connections, props.queryData, props.showProviders))
    if (props.disableConnections) {
      setSelectedGroup(getRegionsGroup())
    } else {
      setSelectedGroup(getMyCloudsGroup(props.disableConnections))
    }
    props.setSelectedZone()
  }, [props.connections, props.queryData, props.showProviders])

  const defaultSortFn = (a: DashboardSelectorOption, b: DashboardSelectorOption) => {
    if (usageFacet === 'carbonfree') {
      return ((b.cfUsage / b.usage) || 0 * 100) - ((a.cfUsage / a.usage) || 0 * 100)
    } else if (usageFacet === 'usage') {
      return b.usage - a.usage
    } else if (usageFacet === 'emissions') {
      return b.emissions - a.emissions
    } else {
      return b.cost - a.cost
    }
  }
  const tagSortFn = (a: DashboardSelectorOption, b: DashboardSelectorOption) => {
    if (sortBy === 'name') {
      return a.key.localeCompare(b.key)
    } else {
      return defaultSortFn(a, b)
    }
  }

  useEffect(() => {
    // sort direction, or facet selection changes: update the list of options
    const newOptions = [...currentOptions]
    newOptions.sort(selectedGroup?.name === 'Tags' ? tagSortFn : defaultSortFn)
    setCurrentOptions(newOptions)
  }, [sortBy, usageFacet])

  useEffect(() => {
    // group selection changes: update the list of options
    if (selectedGroup?.name === 'Regions' && regionOptions.length) {
      const newRegionOptions = [...regionOptions]
      newRegionOptions.sort(defaultSortFn)
      setRegionOptions(newRegionOptions)
      setCurrentOptions(newRegionOptions)
      setSelectedOption(newRegionOptions[0])
    } else if (selectedGroup?.name === 'Tags' && tagOptions.length) {
      const newTagOptions = [...tagOptions]
      newTagOptions.sort(tagSortFn)
      setTagOptions(newTagOptions)
      setCurrentOptions(newTagOptions)
      setSelectedTags([newTagOptions[0] as TagSelection])
    } else if (selectedGroup?.name === 'My Clouds' && cloudOptions.length) {
      const newCloudOptions = [...cloudOptions]
      newCloudOptions.sort(defaultSortFn)
      setCloudOptions(newCloudOptions)
      setCurrentOptions(newCloudOptions)
      setSelectedOption(newCloudOptions[0])
    }
  }, [selectedGroup])

  useEffect(() => {
    // An option was clicked from the Dashboard Selector or the filter strategy changed: update the dashboard data
    props.setDashboardData(aggregateDashboardData(
      Object.values(props.queryData.connectionData),
      props.queryData.gridAverages,
      selectedOption?.cloudProvider,
      selectedOption?.availabilityZone,
      selectedOption?.orgId,
      getTagDataFilter(filterAs, selectedTags, selectedGroup)
    ))
  }, [selectedOption, selectedTags, filterAs])

  useEffect(() => {
    // A region was clicked on the map: update the dashboard data
    if (selectedGroup?.name === 'Regions') {
      // Regions group was already selected, switch to that region option
      const newSelection = regionOptions.filter(o => o.availabilityZone === props.selectedZone)
      if (newSelection?.length) {
        setSelectedOption(newSelection[0])
      }
    } else {
      // Some other group was selected, keep the selected option and filter the data on the new region
      props.setDashboardData(aggregateDashboardData(
        Object.values(props.queryData.connectionData),
        props.queryData.gridAverages,
        selectedOption?.cloudProvider,
        props.selectedZone,
        selectedOption?.orgId,
        getTagDataFilter(filterAs, selectedTags, selectedGroup)
      ))
    }
  }, [props.selectedZone])

  // useEffect(() => {
  //   // The selected providers was updated
  //   setSelectedOption(currentOptions.length ? currentOptions[0] : undefined)
  // }, [props.showProviders])

  const {
    isOpen: isAddTagsOpen,
    onOpen: onAddTagsOpen,
    onClose: onAddTagsClose
  } = useDisclosure()

  return (
    <Grid className='dashboard-selector'>
      <DashboardGroupSelector
        groups={[
          getMyCloudsGroup(props.disableConnections),
          getRegionsGroup(),
          getTagsGroup(props.connections, setConnectionForTagsModal, onAddTagsOpen, props.disableConnections)
        ]}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        setSelectedOption={setSelectedOption}
      />
      <Grid templateRows='2rem auto' templateColumns='100%' overflow='hidden' p={4}>
        {props.disableConnections
          ? <Flex gap={2} justifyContent='end' alignItems='center'>
            <Image src='/icons/horizontalbars.svg' />
            <Text fontSize='sm' color={grey(87)}>REGION AVG</Text>
          </Flex>
          : <Grid templateColumns='50% auto' gap={2} justifyContent='stretch' alignItems='center' h={8}>
            {selectedGroup?.name === 'Tags'
              ? <DashboardSelectorSort sortBy={sortBy} setSortBy={setSortBy} filterAs={filterAs}
                setFilterAs={setFilterAs} />
              : <div />
            }
            <UsageFacetSwitch option={usageFacet} setOption={setUsageFacet}
              isDisabled={props.disableConnections} />
          </Grid>
        }
        <DashboardOptionSelector
          connections={props.connections}
          group={selectedGroup}
          options={currentOptions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          onAddTagsOpen={onAddTagsOpen}
          setConnectionForTagsModal={setConnectionForTagsModal}
          usageFacet={usageFacet}
        />
        <AddTagsModal
          isOpen={isAddTagsOpen}
          onClose={onAddTagsClose}
          connection={connectionForTagsModal}
        />
      </Grid>
    </Grid >
  )
}
