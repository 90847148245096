import { Button, Grid, Image } from '@chakra-ui/react'
import React from 'react'
import { grey } from '../Util'

export interface SelectedProviders {
  AWS: boolean
  Azure: boolean
  GCP: boolean
}

interface CloudProviderSelectorProps {
  showProviders: SelectedProviders
  setShowProviders: (providers: SelectedProviders) => void
}

export const CloudProviderSelector = (props: CloudProviderSelectorProps) => {
  return (
    <Grid className='cloud-provider-selector' gap={3} templateColumns='repeat(3, 1fr)'>
      <Button bg={props.showProviders.AWS ? grey(24) : grey(0)} _hover={{ bg: grey(24) }} w={30} p={0}
        onClick={() => { props.setShowProviders({ ...props.showProviders, AWS: !props.showProviders.AWS }) }}>
        <Image src='/icons/aws_small.svg' height={4} />
      </Button>
      <Button bg={props.showProviders.Azure ? grey(24) : grey(0)} _hover={{ bg: grey(24) }} w={30} p={0}
        onClick={() => { props.setShowProviders({ ...props.showProviders, Azure: !props.showProviders.Azure }) }}>
        <Image src='/icons/azure_small.svg' height={4} />
      </Button>
      <Button bg={props.showProviders.GCP ? grey(24) : grey(0)} _hover={{ bg: grey(24) }} w={30} p={0} isDisabled
        onClick={() => { props.setShowProviders({ ...props.showProviders, GCP: !props.showProviders.GCP }) }}>
        <Image src='/icons/gcp_small.svg' height={4} />
      </Button>
    </Grid>
  )
}
