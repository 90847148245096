import React, { ReactElement } from 'react'
import { Info } from 'react-feather'
import { grey } from '../Util'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import './SharedComponents.css'

interface InfoButtonProps {
  buttonId: string
  buttonSize: number
  infoText: string | ReactElement
}

export const InfoButton = ({ infoText, buttonSize, buttonId }: InfoButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <div style={{ zIndex: 999 }}>
      <div id={buttonId} style={{ cursor: 'pointer' }} onClick={onOpen}>
        <Info color={grey(60)} size={buttonSize} />
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
        <ModalOverlay />
        <ModalContent className='tooltip-modal'>
          <ModalCloseButton />
          <ModalBody>
            {infoText}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}
