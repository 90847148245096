import { Connections } from './Connections/Connections'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthenticationGuard } from './Auth/AuthenticationGuard'
import { Dashboard } from './Dashboard/Dashboard'
import { Invite } from './Invite/Invite'
import { Onboarding } from './Onboarding/Onboarding'
import { Sidenav } from './Sidenav/Sidenav'
import React, { useEffect } from 'react'
import { Grid } from '@chakra-ui/react'
import { Demo } from './Demo/Demo'
import { LoadingSpinner } from './SharedComponents/LoadingSpinner'
import { OAuthError, useAuth0 } from '@auth0/auth0-react'

export const App = () => {
  const {
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect
  } = useAuth0()

  const checkAuthExpired = async () => {
    try {
      await getAccessTokenSilently()
    } catch (e) {
      // Handle an expired token by redirecting to login
      if ((e as OAuthError).error === 'invalid_grant') {
        loginWithRedirect()
      }
    }
  }

  useEffect(() => {
    checkAuthExpired()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }
  return (
    <Grid templateColumns="6rem auto" h="100%">
      <Sidenav />
      <Routes>
        <Route path="/" element={<Navigate to="/clouds" replace />} />
        <Route path="/onboarding" element={<AuthenticationGuard component={Onboarding} />} />
        <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard} />} />
        <Route path="/clouds" element={<AuthenticationGuard component={Connections} />} />
        <Route path="/invite" element={<AuthenticationGuard component={Invite} />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </Grid>
  )
}
