import { Select } from '@chakra-ui/react'
import React from 'react'
import { grey } from '../Util'

export type UsageFacet = 'carbonfree' | 'usage' | 'emissions' | 'cost'

interface UsageFacetSwitchProps {
  option: UsageFacet
  setOption: (option: UsageFacet) => void
  isDisabled?: boolean
}

export interface HasUsageFacets { key: string, usage: number, emissions: number, cost: number, cfUsage: number }

export const getFacetValue = (facetType: UsageFacet, item: HasUsageFacets): string => {
  if (facetType === 'carbonfree') {
    return item.usage > 0 ? Math.round((item.cfUsage / item.usage) * 100) + '%' : 'N/A'
  } else if (facetType === 'usage') {
    return Math.round(item.usage) + ' kWh'
  } else if (facetType === 'emissions') {
    return Math.round(item.emissions) + ' kgCO2e'
  } else {
    return item.cost + '$'
  }
}

export const UsageFacetSwitch = (props: UsageFacetSwitchProps) => {
  return (
    <Select variant='unstyled' value={props.option} size='xs' color={grey(60)}
      onChange={(e) => { props.setOption(e.target.value as UsageFacet) }}>
      <option style={{ backgroundColor: '#212121', color: grey(60) }} value='carbonfree'>CARBON-FREE (%)</option>
      <option style={{ backgroundColor: '#212121', color: grey(60) }} value='usage'>USAGE (kWh)</option>
      <option style={{ backgroundColor: '#212121', color: grey(60) }} value='emissions'>EMISSIONS (kgCO2e)</option>
      <option style={{ backgroundColor: '#212121', color: grey(60) }} value='cost' disabled>COST ($)</option>
    </Select>
  )
}
