import React from 'react'
import { Flex } from '@chakra-ui/react'

interface HeaderProps {
  children?: React.ReactElement
}

export const Header = ({ children }: HeaderProps) => {
  return (
    <Flex
      h="100px"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex="sticky"
      w="full"
      bg="hw.1000"
      pr={6}
    >
      {children}
    </Flex>
  )
}
