import { MercuryConnection } from '@headway-cooperative/project-mercury-utils'
import { DashboardSelectorOption } from './DashboardOptionSelector'
import { DashboardDataResponse } from '../../Api/Api'
import { SelectedProviders } from '../../Header/CloudProviderSelector'
import { Flex, Image, Text } from '@chakra-ui/react'
import { DashboardSelectorGroup } from './DashboardGroupSelector'
import GeoJSON from 'geojson'
import DataCentersJSON from '../../assets/geojson.json'
import { AvailabilityZone } from '../../Util'

export const getRegionsGroup = (): DashboardSelectorGroup => ({
  name: 'Regions',
  element: (
    <Flex gap={2.5}>
      <Image src='/icons/world.svg' />
      <Text fontWeight='bold' fontSize='sm'>Regions</Text>
    </Flex>
  )
})

export const getRegionOptions = (
  connections: MercuryConnection[],
  queryData: DashboardDataResponse,
  showProviders: SelectedProviders,
  disableConnections?: boolean
) => {
  const dataCenters = DataCentersJSON as GeoJSON.FeatureCollection<GeoJSON.Geometry>
  const zoneToName: Record<string, string> = {}
  dataCenters.features.forEach(feature => {
    const zone = feature.properties as AvailabilityZone
    zoneToName[zone.azName] = `${zone.name} (${zone.azName})`
  })

  const options: Record<string, DashboardSelectorOption> = {}
  connections.forEach(c => {
    if (showProviders && !showProviders[c.cloudProvider]) {
      return
    }
    if (queryData.connectionData[c.id]) {
      queryData.connectionData[c.id].rawData.forEach(d => {
        const name = `${c.cloudProvider} ${zoneToName[d.availabilityZone]}`
        if (!options[name]) {
          options[name] = {
            key: name,
            name,
            cloudProvider: c.cloudProvider,
            availabilityZone: d.availabilityZone,
            usage: 0,
            emissions: 0,
            cost: 0,
            cfUsage: 0
          }
        }
        options[name].usage += d.kWh
        options[name].emissions += (d.kWh * d.carbonIntensity) / 1000
        options[name].cost += d.cost
        options[name].cfUsage += (d.kWh * (d.carbonFreePercentage / 100))

        if (disableConnections) {
          // demo page will use gridAverage rather than normal cfUsage
          options[name].cfUsage = queryData.gridAverages[`${c.cloudProvider}<|>${d.availabilityZone}`] || 0
          options[name].usage = 100
        }
      })
    }
  })
  return Object.values(options)
}
