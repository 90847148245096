import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { addUserToOrg } from '../Api/Api'
import { useAuth0 } from '@auth0/auth0-react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../SharedComponents/LoadingSpinner'
import { Center } from '@chakra-ui/react'

export const Invite = () => {
  const [searchParams] = useSearchParams()
  const auth0 = useAuth0()
  const {
    isLoading: addUserLoading,
    data: addUserData,
    error: addUserError
  } = useQuery({
    queryKey: ['addUserToOrg'],
    queryFn: async () => await addUserToOrg(searchParams.get('inviteToken')!, auth0)
  })

  if (addUserError) {
    throw new Error((addUserError as Error).message)
  }

  if (addUserLoading || !addUserData) {
    return <Center minHeight='100%'>
      <LoadingSpinner />
    </Center>
  }
  return <Navigate to='/' />
}
