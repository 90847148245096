import { MercuryConnection, MercuryDataPoint, groupBy } from '@headway-cooperative/project-mercury-utils'
import * as rs from 'jsrsasign'
import chroma from 'chroma-js'

export const COLOR_SCALE = [
  '#82FFA2',
  '#96FF7F',
  '#FDFF73',
  '#FFD96D',
  '#FFA26C',
  '#FF5663',
  '#C9424C',
  '#962F37',
  '#962F37',
  '#651C22'
]

export const valueToColor = (value: number, max: number): string => {
  const pct = value / max
  const scale = chroma.scale(COLOR_SCALE)
  return scale(1 - pct).hex()
}

export interface AvailabilityZone {
  azName: string
  lat: number
  lng: number
  name: string
  provider: 'AWS' | 'Azure' | 'GCP'
}

export const groupData = (
  data: MercuryDataPoint[],
  groups: Array<keyof MercuryDataPoint>,
  weightFn?: (d: MercuryDataPoint) => number
): MercuryDataPoint[] => {
  // group data by `groupBy`
  // then reduce the resulting arrays by summing co2e, cost and kWh
  // and averaging carbonIntensity and carbonFreePercentage
  // and finally flatten into a single array
  return Object.values(groupBy(data, groups)).map(v => aggregateData(v, weightFn)).flat(1)
}

export const aggregateData = (
  data: MercuryDataPoint[],
  weightFn?: (d: MercuryDataPoint) => number
): MercuryDataPoint => {
  return data.reduce<MercuryDataPoint>((agg: MercuryDataPoint, d: MercuryDataPoint, index, array) => {
    const average = (a: number, b: number) => index === array.length - 1 ? (a + b) / array.length : a + b
    const transform = (a: number, b: number) => weightFn ? (a + (b * weightFn(d))) : average(a, b)
    return {
      ...d,
      co2e: agg.co2e + d.co2e,
      cost: agg.cost + d.cost,
      kWh: agg.kWh + d.kWh,
      carbonIntensity: transform(agg.carbonIntensity, d.carbonIntensity),
      carbonFreePercentage: transform(agg.carbonFreePercentage, d.carbonFreePercentage)
    }
  }, {
    carbonIntensity: 0,
    carbonFreePercentage: 0,
    timestamp: new Date(),
    provider: '',
    availabilityZone: '',
    co2e: 0,
    cost: 0,
    kWh: 0,
    service: '',
    tags: {} as Record<string, string>
  })
}

export const DT_FORMAT = 'E MMM d yyyy h:mm:ss a'

export const getCreateStackURL = (connection: MercuryConnection | null): string => {
  if (connection) {
    const createStackParams = {
      templateURL: 'https://project-mercury-customer-bootstrap.s3.amazonaws.com/mercury-template.yaml',
      stackName: 'MercuryComputing',
      param_mercuryComputingAccountName: connection.id,
      param_mercuryComputingCustomerOrg: connection.mercuryOrganization.id
    }
    const createStackPath =
      'https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate'
    return createStackPath + '?' + new URLSearchParams(createStackParams).toString()
  }
  return ''
}

export const copyLink = (text: string, feedbackFn?: () => void) => {
  navigator.clipboard.writeText(text)
  if (feedbackFn) {
    feedbackFn()
  }
}

const ORG_INVITE_SECRET = process.env.REACT_APP_ORG_INVITE_SECRET

export const generateInviteUrl = (orgId: string) => {
  const inviteTokenPayload = {
    iat: new Date().getTime(),
    exp: (new Date().getTime()) + (24 * 60 * 60 * 1000),
    orgId
  }
  const inviteToken = rs.KJUR.jws.JWS.sign(
    'HS256',
    JSON.stringify({ alg: 'HS256', typ: 'JWT' }),
    JSON.stringify(inviteTokenPayload),
    { utf8: ORG_INVITE_SECRET! }
  )
  return `${window.location.origin}/invite?inviteToken=${inviteToken}`
}

export const grey = (opacity: number) => `rgba(255, 255, 255, ${opacity / 100})`
export const green = 'rgba(132, 252, 136, 0.87)'
export const red = 'rgba(251, 125, 117, 0.87)'

export const EMPTY_TAG = '<empty>'
