import React from 'react'
import { grey, valueToColor } from '../../Util'
import { Text } from '@chakra-ui/react'
import {
  CartesianGrid,
  Dot,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { format as dateFormat } from 'date-fns'
import { CustomTooltip } from './CustomTooltip'
import { MercuryDataPoint } from '@headway-cooperative/project-mercury-utils'

interface CarbonFreeChartProps {
  data: MercuryDataPoint[]
}

export const CarbonFreeChart = (props: CarbonFreeChartProps) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={props.data} syncId='dashboardCharts'>
        <CartesianGrid vertical={false} stroke={grey(12)} />
        <XAxis dataKey='timestamp' minTickGap={40} style={{ fontSize: 12 }}
          tickFormatter={(v) => dateFormat(v, 'hh:mm aa')} />
        <YAxis orientation='left' style={{ fontSize: 12 }} axisLine={false} tickLine={false}
          domain={['dataMin', 'dataMax']} tickFormatter={(v, i) => Math.round(v).toString()}
          minTickGap={1} tickCount={100}>
          <Label
            value='CARBON FREE %'
            position={{ x: 25, y: 20 }}
            angle={-90}
            style={{ fontSize: 12 }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip labelFormatter={(label, payload) => {
          return <Text fontSize='xs' fontWeight='bold'>{`${Math.round(payload[0].value!)}%`}</Text>
        }} />} />
        <Line dataKey='carbonFreePercentage' stroke={grey(60)} dot={({ cx, cy, value }) => {
          const color = valueToColor(value, 100)
          return <Dot key={Math.random()} r={3} cx={cx} cy={cy} stroke={color} fill={color} />
        }} />
      </LineChart>
    </ResponsiveContainer>
  )
}
