import React, { ErrorInfo } from 'react'
import { ErrorPopup } from './ErrorPopup'

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren, ErrorBoundaryState> {
  constructor (props: React.PropsWithChildren) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError (error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ error })
  }

  render () {
    if (this.state.hasError) {
      return <ErrorPopup message={
        <>
          <b>{this.state.error?.message}</b>
          <p>{this.state.error?.stack}</p>
        </>
      }/>
    } else {
      return this.props.children
    }
  }
}
