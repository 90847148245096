import {
  Avatar, Flex,
  Grid,
  IconButton,
  Image,
  MenuButton,
  Spacer, Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import './Sidenav.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { getConnectionsForUser, getUserInfo } from '../Api/Api'
import { MercuryMenu } from '../SharedComponents/MercuryMenu'
import { grey } from '../Util'
import { SendLinkModal } from '../Connections/SendLinkModal'
import { MercuryUser } from '@headway-cooperative/project-mercury-utils'

export const Sidenav = () => {
  const location = useLocation()
  const auth0 = useAuth0()
  const currentPath = location.pathname

  const { data: connections } = useQuery({
    queryKey: ['connections'],
    queryFn: async () => await getConnectionsForUser(auth0)
  })

  const [userInfo, setUserInfo] = useState<MercuryUser | null>(null)
  const {
    isLoading: userLoading,
    data: userData
  } = useQuery({
    queryKey: ['user'],
    queryFn: async () => await getUserInfo(auth0),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (!userLoading && userData) {
      setUserInfo(userData)
    }
  }, [userLoading, userData])

  const {
    isOpen: isInviteOpen,
    onOpen: onInviteOpen,
    onClose: onInviteClose
  } = useDisclosure()

  const inviteUser = () => {
    onInviteOpen()
  }

  const logout = async () => {
    await auth0.logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK } })
  }

  const login = async () => {
    await auth0.loginWithRedirect()
  }

  return (
    <Grid flexDirection="row" className="sidenav">
      <Link to="/clouds">
        <Image src="/mercury_logo.svg" height="2.2rem" width="2.2rem" margin="2rem auto" />
      </Link>

      <SidenavLink linkPath="/clouds"
        text="Clouds"
        icon="/icons/cloud.svg"
        selected={currentPath === '/clouds'}
        disabled={!connections?.length} />

      <SidenavLink linkPath="/dashboard"
        text="Dashboard"
        icon="/icons/dashboard.svg"
        selected={currentPath === '/dashboard' || currentPath === '/demo'}
        disabled={!connections?.filter(c => c.connectionStatus === 'CONNECTED').length} />

      <SidenavLink linkPath="/download"
        text="Data"
        icon="/icons/download.svg"
        selected={currentPath === '/download'}
        disabled={true} />

      <SidenavLink linkPath="/api"
        text="API"
        icon="/icons/api.svg"
        selected={currentPath === '/api'}
        disabled={true} />

      <Spacer />
      {userInfo &&
        <>
          <IconButton aria-label='invite-user'
            isRound={true}
            variant='solid'
            width={12}
            height={12}
            m='auto'
            bg={grey(5)}
            icon={<Image src='/icons/userplus.svg' />}
            onClick={inviteUser}
            isDisabled={!connections || connections.filter(c => c.connectionStatus === 'CONNECTED').length === 0}
            _hover={{ bg: grey(24) }}
          />
          <MercuryMenu menuButton={
            <MenuButton>
              <Avatar
                src={auth0.user?.picture}
                name={auth0.user?.name}
              />
            </MenuButton>} extra={auth0.user && (
              <Flex p={4} gap={2}>
                <Image src={auth0.user.picture} h={12} borderRadius='50%' />
                <Stack>
                  <Text fontSize='xs' fontWeight='bold' color={grey(60)}>{auth0.user.name}</Text>
                  <Text fontSize='xs' fontWeight='bold' color={grey(60)}>{auth0.user.email}</Text>
                </Stack>
              </Flex>
            )} items={auth0.user
              ? [{ icon: '/icons/signout.svg', text: 'Sign out of Mercury', onClick: async () => { await logout() } }]
              : [{ icon: '/icons/signin.svg', text: 'Sign in to Mercury', onClick: async () => { await login() } }]} />
          {connections &&
            <SendLinkModal
              isOpen={isInviteOpen}
              onClose={onInviteClose}
              connection={connections[0]}
              emailTemplate='invite'
            />
          }
        </>}
    </Grid>
  )
}

interface SidenavLinkProps {
  linkPath: string
  icon: string
  text: string
  selected: boolean
  disabled: boolean
}

const SidenavLink = ({ linkPath, icon, text, selected, disabled }: SidenavLinkProps) => {
  const navigate = useNavigate()
  const className = selected
    ? 'sidenav-link sidenav-link-selected'
    : disabled
      ? 'sidenav-link sidenav-link-disabled'
      : 'sidenav-link'

  return (
    <Grid flexDirection="row" justifyContent="center" gap="0.25rem" className={className} onClick={() => {
      if (!disabled) {
        navigate(linkPath)
      }
    }}>
      <Image src={icon} />
      <Text align='center'>{text}</Text>
    </Grid>
  )
}
